import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import API_BASE_URL from '../config';
import { useProjects } from './ProjectContext';
import { useHours } from './HoursContext';
import api from '../services/api';

const RetainerContext = createContext();

export function RetainerProvider({ children }) {
  const [retainers, setRetainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { projects } = useProjects();
  const hoursContext = useHours();

  const fetchRetainers = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get('/retainers');
      const activeRetainers = response.data.filter(retainer => retainer.status !== 'deleted');
      setRetainers(activeRetainers);
    } catch (error) {
      console.error('Error fetching retainers:', error);
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRetainers();
  }, []);

  const addRetainer = async (retainerData) => {
    try {
      setError(null);
      const response = await api.post('/retainers', retainerData);
      const newRetainer = response.data;
      setRetainers([...retainers, newRetainer]);
      return newRetainer;
    } catch (error) {
      console.error('Error adding retainer:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    }
  };

  const updateRetainer = async (id, retainerData) => {
    try {
      setError(null);
      const response = await api.put(`/retainers/${id}`, retainerData);
      const updatedRetainer = response.data;
      setRetainers(retainers.map(r => r.id === id ? updatedRetainer : r));
      return updatedRetainer;
    } catch (error) {
      console.error('Error updating retainer:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    }
  };

  const deleteRetainer = async (id) => {
    try {
      setError(null);
      await api.delete(`/retainers/${id}`);
      setRetainers(retainers.map(retainer => 
        retainer.id === id 
          ? { ...retainer, status: 'deleted', isDeleted: true } 
          : retainer
      ));
    } catch (error) {
      console.error('Error deleting retainer:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    }
  };

  const getRetainerProjects = async (retainerId) => {
    try {
      setError(null);
      const response = await api.get(`/projects?retainerId=${retainerId}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch retainer projects');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching retainer projects:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    }
  };

  const getRetainerById = async (id) => {
    try {
      const response = await api.get(`/retainers/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching retainer:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    }
  };

  const associatedProjectsAndHours = useMemo(() => {
    const projectMap = {};
    const hoursLogs = hoursContext?.hoursLogs || [];
    retainers.forEach(retainer => {
      const retainerProjects = projects.filter(project => 
        project.retainerId === retainer.id && !project.isDeleted
      );
      const hoursUsed = hoursLogs.reduce((total, log) => {
        const project = retainerProjects.find(p => p.id === log.projectId);
        return project ? total + log.hours : total;
      }, 0);
      projectMap[retainer.id] = {
        active: retainerProjects.filter(p => p.status === 'active').length,
        inactive: retainerProjects.filter(p => p.status !== 'active').length,
        hoursUsed
      };
    });
    return projectMap;
  }, [retainers, projects, hoursContext?.hoursLogs]);

  return (
    <RetainerContext.Provider value={{ 
      retainers, 
      loading, 
      error, 
      fetchRetainers, 
      addRetainer, 
      updateRetainer, 
      deleteRetainer,
      getRetainerById,
      getRetainerProjects,
      associatedProjectsAndHours
    }}>
      {children}
    </RetainerContext.Provider>
  );
}

export function useRetainers() {
  const context = useContext(RetainerContext);
  if (context === undefined) {
    throw new Error('useRetainers must be used within a RetainerProvider');
  }
  return context;
}