import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { 
  List, ListItem, ListItemText, ListItemButton, Typography, Box, IconButton,
  Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField,
  Button, // Add this import
  styled, alpha
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useConversation } from '../../contexts/ConversationContext';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const ChatConversationList = ({ selectedConversationId, onSelectConversation, onCreateNewConversation, aiAppId, userId }) => {
  const { conversations, renameConversation, archiveConversation, fetchConversations } = useConversation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuConversation, setMenuConversation] = useState(null);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // Update the useEffect to include userId and aiAppId in the dependency array
  useEffect(() => {
    if (userId && aiAppId) {
      fetchConversations(userId, aiAppId);
    }
  }, [userId, aiAppId, fetchConversations]);

  // Update the filteredConversations to only include conversations for the current user and AI app
  const filteredConversations = useMemo(() => 
    conversations.filter(conv =>
      conv.userId === userId && 
      conv.aiAppId === aiAppId && 
      conv.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [conversations, userId, aiAppId, searchTerm]
  );

  const handleMenuOpen = useCallback((event, conversation) => {
    setAnchorEl(event.currentTarget);
    setMenuConversation(conversation);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRenameClick = useCallback(() => {
    setIsRenameDialogOpen(true);
    setNewName(menuConversation.name);
    handleMenuClose();
  }, [menuConversation]);

  const handleArchiveClick = useCallback(() => {
    setIsArchiveDialogOpen(true);
    handleMenuClose();
  }, []);

  const handleRenameConfirm = useCallback(async () => {
    if (!newName.trim() || !menuConversation) return;
    try {
      console.log('Renaming conversation:', menuConversation.id, newName);
      await renameConversation(menuConversation.id, newName);
      console.log('Rename successful');
      setIsRenameDialogOpen(false);
      setNewName('');
    } catch (error) {
      console.error('Failed to rename conversation:', error);
    }
  }, [menuConversation, newName, renameConversation]);

  const handleArchiveConfirm = useCallback(async () => {
    if (!menuConversation) return;
    try {
      await archiveConversation(menuConversation.id);
      setIsArchiveDialogOpen(false);
    } catch (error) {
      console.error('Failed to archive conversation:', error);
    }
  }, [menuConversation, archiveConversation]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', bgcolor: 'background.paper' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold' }}>Conversations</Typography>
        <IconButton
          onClick={onCreateNewConversation}
          size="small"
          sx={{ color: 'primary.main' }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search conversations..."
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Search>
      <List sx={{ flexGrow: 1, overflow: 'auto', px: 1 }}>
        {filteredConversations.map((conversation) => (
          <ListItem
            key={conversation.id}
            disablePadding
            sx={{ mb: 1 }}
          >
            <ListItemButton
              selected={selectedConversationId === conversation.id}
              onClick={() => onSelectConversation(conversation)}
              sx={{
                borderRadius: 2,
                '&.Mui-selected': {
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                },
              }}
            >
              <ListItemText 
                primary={conversation.name} 
                primaryTypographyProps={{ 
                  noWrap: true,
                  fontWeight: selectedConversationId === conversation.id ? 'bold' : 'normal'
                }}
              />
              <IconButton
                edge="end"
                aria-label="more"
                onClick={(event) => {
                  event.stopPropagation();
                  handleMenuOpen(event, conversation);
                }}
                sx={{ 
                  color: selectedConversationId === conversation.id ? 'inherit' : 'action.active',
                  '&:hover': { backgroundColor: 'action.hover' },
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleRenameClick}>Rename</MenuItem>
        <MenuItem onClick={handleArchiveClick}>Archive</MenuItem>
      </Menu>

      <Dialog open={isRenameDialogOpen} onClose={() => setIsRenameDialogOpen(false)}>
        <DialogTitle>Rename Conversation</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Name"
            type="text"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleRenameConfirm();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRenameDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleRenameConfirm} disabled={!newName.trim()}>Rename</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isArchiveDialogOpen} onClose={() => setIsArchiveDialogOpen(false)}>
        <DialogTitle>Archive Conversation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive this conversation? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsArchiveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleArchiveConfirm} color="error">Archive</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default React.memo(ChatConversationList);
