import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import api from '../services/api';

const TagsContext = createContext();

export function TagsProvider({ children }) {
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTags = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/tags');
      setTags(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchTag = useCallback(async (id) => {
    if (typeof id !== 'string' && typeof id !== 'number') {
      throw new Error('Invalid ID provided for tag fetch');
    }
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/tags/${id}`);
      setCurrentTag(response.data);
      return response.data;
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  const addTag = async (tagData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/tags', tagData);
      const newTag = response.data;
      setTags(prevTags => [...prevTags, newTag]);
      return newTag;
    } catch (error) {
      console.error('Error adding tag:', error);
      const errorMessage = error.response?.data?.message || error.message;
      const errorDetails = error.response?.data?.details || 'No additional details';
      setError(`Failed to add tag: ${errorMessage}. Details: ${errorDetails}`);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateTag = async (id, tagData) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for tag update');
    }
    setLoading(true);
    setError(null);
    try {
      const response = await api.put(`/tags/${id}`, tagData);
      const updatedTag = response.data;
      setTags(prevTags => prevTags.map(tag => tag.id === id ? updatedTag : tag));
      if (currentTag && currentTag.id === id) {
        setCurrentTag(updatedTag);
      }
      return updatedTag;
    } catch (error) {
      console.error('Error updating tag:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteTag = async (id) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for tag deletion');
    }
    setLoading(true);
    setError(null);
    try {
      await api.delete(`/tags/${id}`);
      setTags(prevTags => prevTags.filter(tag => tag.id !== id));
      if (currentTag && currentTag.id === id) {
        setCurrentTag(null);
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <TagsContext.Provider value={{ 
      tags, 
      currentTag, 
      loading, 
      error, 
      fetchTags, 
      fetchTag, 
      addTag, 
      updateTag, 
      deleteTag 
    }}>
      {children}
    </TagsContext.Provider>
  );
}

export function useTags() {
  const context = useContext(TagsContext);
  if (!context) {
    throw new Error('useTags must be used within a TagsProvider');
  }
  return context;
}
