import axios from 'axios';
import API_BASE_URL from '../config';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401 && error.config.url !== '/auth/login') {
        // Only clear token if it's not a login request
        Cookies.remove('token');
        localStorage.removeItem('user');
        window.dispatchEvent(new Event('unauthorized'));
      }
      // You can handle other status codes here if needed
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up request:', error.message);
    }
    return Promise.reject(error);
  }
);

export const login = async (email, password) => {
  try {
    const response = await api.post('/auth/login', { email, password });
    const { token, user } = response.data;
    Cookies.set('token', token, { expires: 7, secure: true, sameSite: 'strict' });
    localStorage.setItem('user', JSON.stringify(user));
    return { token, user };
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const logout = () => {
  Cookies.remove('token');
  localStorage.removeItem('user');
  window.dispatchEvent(new Event('logout'));
};

export const fetchCurrentUser = async () => {
    try {
      const response = await api.get('/users/me');
      return response.data;
    } catch (error) {
      console.error('Error fetching current user:', error);
      throw error;
    }
  };

export default api;