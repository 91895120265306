import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import api from '../services/api';
import { usePortfolioProjects } from './PortfolioProjectContext';

const ProjectContext = createContext();

export function ProjectProvider({ children }) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProjects = useCallback(async (status, clientId) => {
    setLoading(true);
    setError(null);
    try {
      let url = '/projects';
      const params = new URLSearchParams();
      if (status) params.append('status', status);
      if (clientId) params.append('clientId', clientId);
      if (params.toString()) url += `?${params.toString()}`;

      const response = await api.get(url);
      const projectsData = response.data;

      // Fetch retainers to check their status
      const retainersResponse = await api.get('/retainers');
      const retainersData = retainersResponse.data;

      // Filter out projects with deleted retainers
      const filteredProjects = projectsData.filter(project => {
        if (project.projectType === 'retainer') {
          const associatedRetainer = retainersData.find(r => r.id === project.retainerId);
          return associatedRetainer && associatedRetainer.status !== 'deleted';
        }
        return true;
      });

      setProjects(filteredProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError(error.response?.data?.message || 'Failed to fetch projects');
    } finally {
      setLoading(false);
    }
  }, []); // Empty dependency array

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const addProject = async (projectData) => {
    setLoading(true);
    setError(null);
    try {
      // Validate project type and retainer ID
      if (projectData.projectType === 'one-off') {
        projectData.retainerId = null;  // Explicitly set to null for one-off projects
      } else if (projectData.projectType === 'retainer' && !projectData.retainerId) {
        throw new Error('Retainer projects must have a retainer ID');
      }

      // Handle budget based on project type
      if (projectData.projectType === 'retainer') {
        projectData.budget = null;
      } else if (projectData.projectType === 'one-off') {
        // Ensure budget is a number (in cents) for one-off projects
        if (typeof projectData.budget === 'string' && projectData.budget !== '') {
          projectData.budget = Math.round(parseFloat(projectData.budget) * 100);
        } else if (projectData.budget === '') {
          projectData.budget = null;
        }
      }

      const response = await api.post('/projects', projectData);
      await fetchProjects(); // Fetch all projects to ensure consistency
      return response.data;
    } catch (error) {
      console.error('Error adding project:', error);
      setError(error.response?.data?.message || error.message || 'Failed to add project');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateProject = async (id, projectData) => {
    setLoading(true);
    setError(null);
    try {
      // Validate project type and retainer ID
      if (projectData.projectType === 'one-off') {
        projectData.retainerId = null;  // Explicitly set to null for one-off projects
      } else if (projectData.projectType === 'retainer' && !projectData.retainerId) {
        throw new Error('Retainer projects must have a retainer ID');
      }

      // Handle budget based on project type
      if (projectData.projectType === 'retainer') {
        projectData.budget = null;
      } else if (projectData.projectType === 'one-off') {
        // Ensure budget is a number (in cents) for one-off projects
        if (typeof projectData.budget === 'string' && projectData.budget !== '') {
          projectData.budget = Math.round(parseFloat(projectData.budget) * 100);
        } else if (projectData.budget === '') {
          projectData.budget = null;
        }
      }

      console.log('Project data before update:', projectData);
      const response = await api.put(`/projects/${id}`, projectData);
      console.log('Response from server:', response.data);

      await fetchProjects(); // Force a refresh of all projects
      return response.data;
    } catch (error) {
      console.error('Error updating project:', error);
      console.error('Error response:', error.response?.data);
      setError(error.response?.data?.message || error.message || 'Failed to update project');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteProject = async (id) => {
    setLoading(true);
    setError(null);
    try {
      await api.delete(`/projects/${id}`);
      await fetchProjects(); // Fetch all projects to ensure consistency
    } catch (error) {
      console.error('Error deleting project:', error);
      setError(error.response?.data?.message || 'Failed to delete project');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getProjectById = useCallback((id) => {
    return projects.find(project => project.id === id);
  }, [projects]);

  const getProjectsByClient = useCallback((clientId) => {
    return projects.filter(project => project.clientId === clientId);
  }, [projects]);

  const updateMultipleProjects = async (projectUpdates) => {
    setLoading(true);
    setError(null);
    try {
      const updatedProjects = projectUpdates.map(project => {
        if (project.projectType === 'retainer') {
          return { ...project, budget: null };
        } else if (project.projectType === 'one-off') {
          return {
            ...project,
            budget: typeof project.budget === 'string' ? parseFloat(project.budget) : project.budget,
            retainerId: null  // Explicitly set to null for one-off projects
          };
        }
        return project;
      });

      const response = await api.put('/projects/batch', { projects: updatedProjects });
      setProjects(prevProjects => 
        prevProjects.map(project => {
          const updatedProject = response.data.find(p => p.id === project.id);
          return updatedProject ? updatedProject : project;
        })
      );
      return response.data;
    } catch (error) {
      console.error('Error updating multiple projects:', error);
      setError(error.response?.data?.message || 'Failed to update projects');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const [completedProjectsCount, setCompletedProjectsCount] = useState(0);
  const { projects: portfolioProjects } = usePortfolioProjects();

  const updateCompletedProjectsCount = useCallback(() => {
    const count = projects.filter(project => 
      project.status === 'inactive' && 
      !project.isDeleted &&
      !portfolioProjects.some(portfolioProject => 
        portfolioProject && portfolioProject.projectId === project.id
      )
    ).length;
    setCompletedProjectsCount(count);
    localStorage.setItem('completedProjectsCount', count.toString());
  }, [projects, portfolioProjects]);

  useEffect(() => {
    updateCompletedProjectsCount();
  }, [projects, portfolioProjects, updateCompletedProjectsCount]);

  const value = {
    projects,
    loading,
    error,
    fetchProjects,
    addProject,
    updateProject,
    deleteProject,
    getProjectById,
    getProjectsByClient,
    updateMultipleProjects,
    completedProjectsCount,  // Add this
  };

  return (
    <ProjectContext.Provider value={value}>
      {children}
    </ProjectContext.Provider>
  );
}

export function useProjects() {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectProvider');
  }
  return context;
}