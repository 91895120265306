import React, { useState, useCallback, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper, CircularProgress, Container, Fade, Grow, LinearProgress, Tooltip } from '@mui/material';
import { useFormSubmission } from '../../contexts/FormSubmissionContext';
import { useAIApp } from '../../contexts/AIAppContext';
import { styled } from '@mui/system';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '800px',
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow: '0 0 0 4px rgba(0, 122, 255, 0.1)',
    },
    '&.Mui-focused': {
      boxShadow: '0 0 0 4px rgba(0, 122, 255, 0.2)',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: theme.spacing(1.5, 4),
  fontWeight: 'bold',
  textTransform: 'none',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const ResultSection = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  borderRadius: '16px',
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(2),
  height: 10,
  borderRadius: 5,
}));

const StyledInputContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[3],
    fontSize: 14,
    borderRadius: 8,
    padding: theme.spacing(1.5),
  },
}));

const FormInterface = ({ aiApp, selectedFormSubmission }) => {
  const { rerunFormSubmission, createFormSubmission } = useFormSubmission();
  const [formData, setFormData] = useState({});
  const [originalFormData, setOriginalFormData] = useState({});
  const [result, setResult] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewSubmission, setIsNewSubmission] = useState(false);
  const [progress, setProgress] = useState(0);
  const [inputChanged, setInputChanged] = useState(false);

  useEffect(() => {
    if (selectedFormSubmission) {
      const inputData = selectedFormSubmission.inputData || {};
      setFormData(inputData);
      setOriginalFormData(inputData);
      setResult(selectedFormSubmission.outputData || null);
      setIsNewSubmission(false);
      setInputChanged(false);
    } else {
      setFormData({});
      setOriginalFormData({});
      setResult(null);
      setIsNewSubmission(true);
      setInputChanged(false);
    }
  }, [selectedFormSubmission]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (inputChanged) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (inputChanged) {
        setFormData(originalFormData);
      }
    };
  }, [inputChanged, originalFormData]);

  useEffect(() => {
    let timer;
    if (localLoading) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);
    } else {
      setProgress(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [localLoading]);

  const handleInputChange = (e) => {
    const newFormData = { ...formData, [e.target.name]: e.target.value };
    setFormData(newFormData);
    setInputChanged(JSON.stringify(newFormData) !== JSON.stringify(originalFormData));
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLocalLoading(true);
    setError(null);
    setResult(null);
    try {
      if (!aiApp || !aiApp.id) {
        throw new Error('Invalid AI App');
      }
      
      let response;
      if (isNewSubmission) {
        response = await createFormSubmission(aiApp.id, formData, 'New Submission', true);
        setIsNewSubmission(false);
      } else {
        response = await rerunFormSubmission(selectedFormSubmission.id, formData, aiApp.defaultWorkflow);
      }
      
      setResult(response.outputData);
      setOriginalFormData(formData);
      setInputChanged(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(error.message || 'Failed to submit form. Please try again.');
    } finally {
      setLocalLoading(false);
    }
  }, [aiApp, isNewSubmission, selectedFormSubmission, formData, rerunFormSubmission, createFormSubmission]);

  const renderInputFields = () => {
    if (!aiApp.config || !aiApp.config.inputVariables) return null;

    return aiApp.config.inputVariables.map((variable) => {
      const metadata = aiApp.config.inputMetadata?.[variable] || {};
      return (
        <StyledInputContainer key={variable}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
              {metadata.title || variable}
            </Typography>
            {metadata.description && (
              <StyledTooltip title={metadata.description} placement="top-start">
                <HelpOutlineIcon sx={{ ml: 1, fontSize: 18, color: 'text.secondary', cursor: 'help' }} />
              </StyledTooltip>
            )}
          </Box>
          <StyledTextField
            name={variable}
            value={formData[variable] || ''}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            placeholder={metadata.description || `Enter ${variable}`}
          />
        </StyledInputContainer>
      );
    });
  };

  const renderResult = () => {
    if (!result) return null;
    return Object.entries(result).map(([key, value], index) => {
      const metadata = aiApp.config.outputMetadata?.[key] || {};
      // Trim the value to remove leading/trailing quotation marks
      const trimmedValue = typeof value === 'string' ? value.trim().replace(/^"|"$/g, '') : value;
      return (
        <Grow in={true} key={key} timeout={(index + 1) * 500}>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                {metadata.title || key}
              </Typography>
              {metadata.description && (
                <StyledTooltip title={metadata.description} placement="top-start">
                  <HelpOutlineIcon sx={{ ml: 1, fontSize: 18, color: 'text.secondary', cursor: 'help' }} />
                </StyledTooltip>
              )}
            </Box>
            <Typography variant="body1">{trimmedValue}</Typography>
          </Box>
        </Grow>
      );
    });
  };

  if (!aiApp) {
    return <Typography>Loading...</Typography>;
  }

  const hasInput = Object.values(formData).some(value => value !== '');
  const canSubmit = isNewSubmission || inputChanged;
  const buttonText = isNewSubmission || !result ? 'Submit' : 'Rerun';

  return (
    <StyledContainer>
      <StyledForm onSubmit={handleSubmit}>
        {renderInputFields()}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          {hasInput && (
            <StyledButton 
              type="submit" 
              variant="contained" 
              color="primary" 
              disabled={localLoading || !canSubmit}
            >
              {localLoading ? 'Casting spells...' : buttonText}
            </StyledButton>
          )}
        </Box>
      </StyledForm>
      {localLoading && (
        <Fade in={localLoading}>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" align="center" sx={{ mb: 1 }}>
              Casting spells, brb...
            </Typography>
            <StyledLinearProgress variant="determinate" value={progress} />
          </Box>
        </Fade>
      )}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>
      )}
      {result && (
        <Fade in={true} timeout={1000}>
          <ResultSection elevation={0}>
            {renderResult()}
          </ResultSection>
        </Fade>
      )}
    </StyledContainer>
  );
};

export default React.memo(FormInterface);
