import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Divider,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { useAIApp } from '../../contexts/AIAppContext';
import { useUser } from '../../contexts/UserContext';
import { useClients } from '../../contexts/ClientContext';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
}));

const AddEditAIApps = ({ open, handleClose, editingAppId, onSuccess }) => {
  const { createAIApp, updateAIApp, fetchAIApp, fetchAIApps } = useAIApp();
  const { users } = useUser();
  const { getAssociatedClients } = useClients();

  const [appForm, setAppForm] = useState({
    id: null,
    name: '',
    type: 'chat',
    appId: '',
    isActive: true,
    defaultWorkflow: null,
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [inputVariables, setInputVariables] = useState([]);
  const [outputVariables, setOutputVariables] = useState([]);
  const [newInputVariable, setNewInputVariable] = useState('');
  const [newOutputVariable, setNewOutputVariable] = useState('');
  const [workflowText, setWorkflowText] = useState('');
  const [inputMetadata, setInputMetadata] = useState({});
  const [outputMetadata, setOutputMetadata] = useState({});

  useEffect(() => {
    if (editingAppId) {
      const fetchAppData = async () => {
        try {
          const appData = await fetchAIApp(editingAppId);
          setAppForm({
            id: appData.id,
            name: appData.name,
            type: appData.type,
            appId: appData.mindstudioAppId,
            isActive: appData.isActive,
            defaultWorkflow: appData.defaultWorkflow || null,
          });
          setSelectedUsers(appData.Users.map(user => user.id));
          const config = typeof appData.config === 'string' ? JSON.parse(appData.config) : appData.config;
          setInputVariables(config.inputVariables || []);
          setOutputVariables(config.outputVariables || []);
          setInputMetadata(config.inputMetadata || {});
          setOutputMetadata(config.outputMetadata || {});
          setWorkflowText(appData.defaultWorkflow || '');
        } catch (error) {
          console.error('Error fetching AI app data:', error);
          toast.error('Failed to load AI app data');
        }
      };
      fetchAppData();
    } else {
      resetForm();
    }
  }, [editingAppId, fetchAIApp]);

  const resetForm = () => {
    setAppForm({
      id: null,
      name: '',
      type: 'chat',
      appId: '',
      isActive: true,
      defaultWorkflow: null,
    });
    setSelectedUsers([]);
    setInputVariables([]);
    setOutputVariables([]);
    setNewInputVariable('');
    setNewOutputVariable('');
    setWorkflowText('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAppForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleUserSelect = (e) => {
    setSelectedUsers(e.target.value);
  };

  const handleMetadataChange = (type, variable, field, value) => {
    const setMetadata = type === 'input' ? setInputMetadata : setOutputMetadata;
    setMetadata(prev => ({
      ...prev,
      [variable]: { ...prev[variable], [field]: value }
    }));
  };

  const handleAddVariable = (type) => {
    if (type === 'input' && newInputVariable.trim()) {
      setInputVariables([...inputVariables, newInputVariable.trim()]);
      setInputMetadata(prev => ({
        ...prev,
        [newInputVariable.trim()]: { title: '', description: '' }
      }));
      setNewInputVariable('');
    } else if (type === 'output' && newOutputVariable.trim()) {
      setOutputVariables([...outputVariables, newOutputVariable.trim()]);
      setOutputMetadata(prev => ({
        ...prev,
        [newOutputVariable.trim()]: { title: '', description: '' }
      }));
      setNewOutputVariable('');
    }
  };

  const handleRemoveVariable = (type, variable) => {
    if (type === 'input') {
      setInputVariables(inputVariables.filter((v) => v !== variable));
      setInputMetadata(prev => {
        const { [variable]: removed, ...rest } = prev;
        return rest;
      });
    } else {
      setOutputVariables(outputVariables.filter((v) => v !== variable));
      setOutputMetadata(prev => {
        const { [variable]: removed, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleWorkflowChange = (e) => {
    setWorkflowText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        name: appForm.name,
        type: appForm.type,
        mindstudioAppId: appForm.appId,
        config: JSON.stringify({
          inputVariables,
          outputVariables,
          inputMetadata,
          outputMetadata,
        }),
        defaultWorkflow: workflowText, // Use workflowText directly
        isActive: appForm.isActive,
        userIds: selectedUsers.map(id => parseInt(id, 10)),
      };

      console.log('Submitting form data:', formData);

      let updatedApp;
      if (editingAppId) {
        updatedApp = await updateAIApp(editingAppId, formData);
        toast.success('AI App updated successfully');
      } else {
        updatedApp = await createAIApp(formData);
        toast.success('AI App created successfully');
      }

      // Refresh the AI apps list
      await fetchAIApps();

      handleClose();
      resetForm();
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error('Error saving AI App:', error);
      console.error('Error details:', error.response?.data);
      
      let errorMessage = 'An unknown error occurred';
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      toast.error(`Error saving AI App: ${errorMessage}`);
      
      if (error.response?.data?.details) {
        console.error('Additional error details:', error.response.data.details);
        toast.error(`Additional details: ${JSON.stringify(error.response.data.details)}`);
      }
    }
  };

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleKeyPress = (event, type) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddVariable(type);
    }
  };

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{editingAppId ? 'Edit AI App' : 'Create New AI App'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <StyledPaper elevation={0}>
            <Typography variant="h6" gutterBottom>Basic Information</Typography>
            <TextField
              fullWidth
              margin="normal"
              name="name"
              label="App Name"
              value={appForm.name}
              onChange={handleInputChange}
              required
              variant="outlined"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>App Type</InputLabel>
              <Select
                name="type"
                value={appForm.type}
                onChange={handleInputChange}
                required
              >
                <MenuItem value="chat">Chat</MenuItem>
                <MenuItem value="form">Form</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              name="appId"
              label="MindStudio App ID"
              value={appForm.appId}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              name="workflow"
              label="Default Workflow"
              value={workflowText}
              onChange={(e) => setWorkflowText(e.target.value)}
              multiline
              rows={2}
              variant="outlined"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={appForm.isActive}
                  onChange={(e) => setAppForm((prev) => ({ ...prev, isActive: e.target.checked }))}
                  name="isActive"
                />
              }
              label="Active"
            />
          </StyledPaper>

          <StyledPaper elevation={0}>
            <Typography variant="h6" gutterBottom>User Assignment</Typography>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel id="assign-users-label" shrink>
                Assign Users
              </InputLabel>
              <Select
                labelId="assign-users-label"
                multiple
                value={selectedUsers}
                onChange={handleUserSelect}
                label="Assign Users"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={users.find((user) => user.id === value)?.name}
                        color="primary"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                )}
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledPaper>

          {appForm.type === 'form' && (
            <StyledPaper elevation={0}>
              <Typography variant="h6" gutterBottom>Variables and Metadata</Typography>
              
              {/* Input Variables */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" gutterBottom>Input Variables</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <TextField
                    value={newInputVariable}
                    onChange={(e) => setNewInputVariable(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleAddVariable('input')}
                    label="New Input Variable"
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1, flexGrow: 1 }}
                  />
                  <IconButton onClick={() => handleAddVariable('input')} color="primary">
                    <AddIcon />
                  </IconButton>
                </Box>
                {inputVariables.map((variable) => (
                  <Paper key={variable} elevation={1} sx={{ p: 2, mb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography variant="subtitle2">{variable}</Typography>
                      <IconButton onClick={() => handleRemoveVariable('input', variable)} size="small">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <TextField
                      label="Title"
                      value={inputMetadata[variable]?.title || ''}
                      onChange={(e) => handleMetadataChange('input', variable, 'title', e.target.value)}
                      fullWidth
                      margin="dense"
                      size="small"
                    />
                    <TextField
                      label="Description"
                      value={inputMetadata[variable]?.description || ''}
                      onChange={(e) => handleMetadataChange('input', variable, 'description', e.target.value)}
                      fullWidth
                      margin="dense"
                      size="small"
                      multiline
                      rows={2}
                    />
                  </Paper>
                ))}
              </Box>

              {/* Output Variables */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" gutterBottom>Output Variables</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <TextField
                    value={newOutputVariable}
                    onChange={(e) => setNewOutputVariable(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleAddVariable('output')}
                    label="New Output Variable"
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1, flexGrow: 1 }}
                  />
                  <IconButton onClick={() => handleAddVariable('output')} color="primary">
                    <AddIcon />
                  </IconButton>
                </Box>
                {outputVariables.map((variable) => (
                  <Paper key={variable} elevation={1} sx={{ p: 2, mb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography variant="subtitle2">{variable}</Typography>
                      <IconButton onClick={() => handleRemoveVariable('output', variable)} size="small">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <TextField
                      label="Title"
                      value={outputMetadata[variable]?.title || ''}
                      onChange={(e) => handleMetadataChange('output', variable, 'title', e.target.value)}
                      fullWidth
                      margin="dense"
                      size="small"
                    />
                    <TextField
                      label="Description"
                      value={outputMetadata[variable]?.description || ''}
                      onChange={(e) => handleMetadataChange('output', variable, 'description', e.target.value)}
                      fullWidth
                      margin="dense"
                      size="small"
                      multiline
                      rows={2}
                    />
                  </Paper>
                ))}
              </Box>
            </StyledPaper>
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={handleClose} variant="outlined">Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {editingAppId ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default AddEditAIApps;
