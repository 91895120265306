import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';

const HoursContext = createContext();

export function HoursProvider({ children }) {
  const [hoursLogs, setHoursLogs] = useState([]);
  const [hoursLoading, setHoursLoading] = useState(true);

  useEffect(() => {
    fetchHoursLogs();
  }, []);

  const fetchHoursLogs = async () => {
    try {
      const response = await api.get('/hours');
      const sortedData = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setHoursLogs(sortedData);
      setHoursLoading(false);
    } catch (error) {
      console.error('Error fetching hours:', error);
      setHoursLoading(false);
    }
  };

  const updateRetainerHours = async (retainerId, newHoursUsed) => {
    try {
      await api.put(`/retainers/${retainerId}`, { hoursUsed: newHoursUsed });
    } catch (error) {
      console.error('Error updating retainer hours:', error);
    }
  };

  const addHoursLog = async (hoursData) => {
    try {
      const response = await api.post('/hours', hoursData);
      const newLog = response.data;

      setHoursLogs([newLog, ...hoursLogs]);

      const projectResponse = await api.get(`/projects/${newLog.projectId}`);
      const project = projectResponse.data;
      if (project.retainerId) {
        const newHoursUsed = project.retainer.hoursUsed + newLog.hours;
        await updateRetainerHours(project.retainerId, newHoursUsed);
      }
    } catch (error) {
      console.error('Error logging hours:', error);
    }
  };

  const updateHoursLog = async (id, hoursData) => {
    try {
      const logResponse = await api.get(`/hours/${id}`);
      const existingLog = logResponse.data;

      const response = await api.put(`/hours/${id}`, hoursData);
      const updatedLog = response.data;

      setHoursLogs(hoursLogs.map(log => log.id === id ? updatedLog : log));

      const projectResponse = await api.get(`/projects/${updatedLog.projectId}`);
      const project = projectResponse.data;
      if (project.retainerId) {
        const newHoursUsed = project.retainer.hoursUsed - existingLog.hours + updatedLog.hours;
        await updateRetainerHours(project.retainerId, newHoursUsed);
      }
    } catch (error) {
      console.error('Error updating hours log:', error);
    }
  };

  const deleteHoursLog = async (id) => {
    try {
      const logResponse = await api.get(`/hours/${id}`);
      const existingLog = logResponse.data;

      await api.delete(`/hours/${id}`);
      setHoursLogs(hoursLogs.filter(log => log.id !== id));

      const projectResponse = await api.get(`/projects/${existingLog.projectId}`);
      const project = projectResponse.data;
      if (project.retainerId) {
        const newHoursUsed = project.retainer.hoursUsed - existingLog.hours;
        await updateRetainerHours(project.retainerId, newHoursUsed);
      }
    } catch (error) {
      console.error('Error deleting hours log:', error);
    }
  };

  const fetchHoursByProjectId = async (projectId) => {
    try {
      const response = await api.get(`/hours/project/${projectId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching hours for project:', error);
      throw error;
    }
  };

  const fetchHoursByCreativeId = async (creativeId) => {
    try {
      const response = await api.get(`/hours/creative/${creativeId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching hours for creative:', error);
      throw error;
    }
  };

  return (
    <HoursContext.Provider value={{ 
      hoursLogs, 
      fetchHoursLogs, 
      addHoursLog, 
      updateHoursLog, 
      deleteHoursLog, 
      fetchHoursByProjectId,
      fetchHoursByCreativeId,
      loading: hoursLoading 
    }}>
      {children}
    </HoursContext.Provider>
  );
}

export function useHours() {
  return useContext(HoursContext);
}