import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import api from '../services/api';

const ServicesContext = createContext();

export function ServicesProvider({ children }) {
  const [services, setServices] = useState([]);
  const [currentService, setCurrentService] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchServices = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/services');
      setServices(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchService = useCallback(async (id) => {
    if (typeof id !== 'string' && typeof id !== 'number') {
      throw new Error('Invalid ID provided for service fetch');
    }
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/services/${id}`);
      setCurrentService(response.data);
      return response.data;
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const addService = async (serviceData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/services', serviceData);
      const newService = response.data;
      setServices(prevServices => [...prevServices, newService]);
      return newService;
    } catch (error) {
      console.error('Error adding service:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateService = async (id, serviceData) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for service update');
    }
    setLoading(true);
    setError(null);
    try {
      const response = await api.put(`/services/${id}`, serviceData);
      const updatedService = response.data;
      setServices(prevServices => prevServices.map(service => service.id === id ? updatedService : service));
      if (currentService && currentService.id === id) {
        setCurrentService(updatedService);
      }
      return updatedService;
    } catch (error) {
      console.error('Error updating service:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteService = async (id) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for service deletion');
    }
    setLoading(true);
    setError(null);
    try {
      await api.delete(`/services/${id}`);
      setServices(prevServices => prevServices.filter(service => service.id !== id));
      if (currentService && currentService.id === id) {
        setCurrentService(null);
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <ServicesContext.Provider value={{ 
      services, 
      currentService, 
      loading, 
      error, 
      fetchServices, 
      fetchService, 
      addService, 
      updateService, 
      deleteService 
    }}>
      {children}
    </ServicesContext.Provider>
  );
}

export function useServices() {
  const context = useContext(ServicesContext);
  if (!context) {
    throw new Error('useServices must be used within a ServicesProvider');
  }
  return context;
}
