import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import api from '../services/api';

const AssetTypeContext = createContext();

export function AssetTypeProvider({ children }) {
  const [assetTypes, setAssetTypes] = useState([]);
  const [currentAssetType, setCurrentAssetType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAssetTypes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/asset-types');
      setAssetTypes(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchAssetType = useCallback(async (id) => {
    if (typeof id !== 'string' && typeof id !== 'number') {
      throw new Error('Invalid ID provided for asset type fetch');
    }
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/asset-types/${id}`);
      setCurrentAssetType(response.data);
      return response.data;
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAssetTypes();
  }, [fetchAssetTypes]);

  const addAssetType = async (assetTypeData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/asset-types', assetTypeData);
      const newAssetType = response.data;
      setAssetTypes(prevAssetTypes => [...prevAssetTypes, newAssetType]);
      return newAssetType;
    } catch (error) {
      console.error('Error adding asset type:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateAssetType = async (id, assetTypeData) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for asset type update');
    }
    setLoading(true);
    setError(null);
    try {
      const response = await api.put(`/asset-types/${id}`, assetTypeData);
      const updatedAssetType = response.data;
      setAssetTypes(prevAssetTypes => prevAssetTypes.map(assetType => assetType.id === id ? updatedAssetType : assetType));
      if (currentAssetType && currentAssetType.id === id) {
        setCurrentAssetType(updatedAssetType);
      }
      return updatedAssetType;
    } catch (error) {
      console.error('Error updating asset type:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteAssetType = async (id) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for asset type deletion');
    }
    setLoading(true);
    setError(null);
    try {
      await api.delete(`/asset-types/${id}`);
      setAssetTypes(prevAssetTypes => prevAssetTypes.filter(assetType => assetType.id !== id));
      if (currentAssetType && currentAssetType.id === id) {
        setCurrentAssetType(null);
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <AssetTypeContext.Provider value={{ 
      assetTypes, 
      currentAssetType, 
      loading, 
      error, 
      fetchAssetTypes, 
      fetchAssetType, 
      addAssetType, 
      updateAssetType, 
      deleteAssetType 
    }}>
      {children}
    </AssetTypeContext.Provider>
  );
}

export function useAssetTypes() {
  const context = useContext(AssetTypeContext);
  if (!context) {
    throw new Error('useAssetTypes must be used within an AssetTypeProvider');
  }
  return context;
}