import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Card, CardContent, Typography, Chip, TextField, Select, MenuItem, FormControl, InputLabel, Grid, Fab, IconButton, Switch, FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled, alpha } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';
import CategoryIcon from '@mui/icons-material/Category';
import BusinessIcon from '@mui/icons-material/Business';
import UpdateIcon from '@mui/icons-material/Update';
import { useAIApp } from '../../contexts/AIAppContext';
import { useUser } from '../../contexts/UserContext';
import { useClients } from '../../contexts/ClientContext';
import AddEditAIApps from './AddEditAIApps';
import DeleteConfirmationDialog from '../common/DeleteConfirmationDialog';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NoAIAppsMessage from './NoAIAppsMessage';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
    '& .actionButtons': {
      opacity: 1,
    },
  },
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
}));

const CardHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.grey[700]}, ${theme.palette.grey[900]})`,
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  position: 'relative',
}));

const AddButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
}));

const IconContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(0.5),
  background: 'rgba(255, 255, 255, 0.2)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const InfoChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  color: theme.palette.common.white,
  margin: theme.spacing(0.5),
  '& .MuiChip-label': {
    fontWeight: 400,
    padding: '0 8px',
  },
  '& .MuiChip-icon': {
    color: theme.palette.common.white,
    marginLeft: '8px',
  },
  height: '28px',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.25),
  borderRadius: theme.shape.borderRadius,
  height: 20,
  '& .MuiChip-label': {
    padding: '0 6px',
    fontSize: '0.7rem',
  },
}));

const TypeChip = styled(StyledChip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  color: theme.palette.secondary.main,
  '& .MuiChip-icon': {
    color: theme.palette.secondary.main,
  },
}));

const UserChip = styled(StyledChip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  '& .MuiChip-icon': {
    color: theme.palette.primary.main,
  },
}));

const ClientChip = styled(StyledChip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.grey[500], 0.1),
  color: theme.palette.text.secondary,
  '& .MuiChip-icon': {
    color: theme.palette.text.secondary,
  },
}));

const AIPage = () => {
  const { user } = useUser();
  const { aiApps: aiAppsData, fetchAIApps, fetchUserAIApps, deleteAIApp, toggleAIAppActive } = useAIApp();
  const { clients, getAssociatedClients } = useClients();
  const [search, setSearch] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedAIApp, setSelectedAIApp] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [aiAppToDelete, setAIAppToDelete] = useState(null);
  const [userAIApps, setUserAIApps] = useState([]);
  const navigate = useNavigate();
  const isAdmin = user.permission === 'admin';
  const [activeFilter, setActiveFilter] = useState('all');

  const refreshAIApps = useCallback(async () => {
    try {
      if (isAdmin) {
        await fetchAIApps();
      } else {
        const userApps = await fetchUserAIApps(user.id);
        setUserAIApps(userApps);
      }
    } catch (error) {
      console.error('Error refreshing AI Apps:', error);
      toast.error('Failed to refresh AI Apps. Please try again.');
    }
  }, [fetchAIApps, fetchUserAIApps, isAdmin, user.id]);

  useEffect(() => {
    refreshAIApps();
  }, [refreshAIApps]);

  const filteredAIApps = useMemo(() => {
    const appsToFilter = isAdmin ? aiAppsData.aiApps : userAIApps;
    if (!appsToFilter || !Array.isArray(appsToFilter)) {
      console.error('aiApps is not an array:', appsToFilter);
      return [];
    }
    return appsToFilter.filter(app => 
      (app.name.toLowerCase().includes(search.toLowerCase()) ||
       (isAdmin && app.type.toLowerCase().includes(search.toLowerCase())) ||
       (isAdmin && app.Users?.some(user => user.name.toLowerCase().includes(search.toLowerCase()))) ||
       (isAdmin && app.associatedClients?.some(clientId => 
         clients.find(c => c.id === clientId)?.name.toLowerCase().includes(search.toLowerCase())
       ))) &&
      (selectedType === '' || app.type === selectedType) &&
      (isAdmin ? 
        (activeFilter === 'all' || (activeFilter === 'active' && app.isActive) || (activeFilter === 'inactive' && !app.isActive))
        : app.isActive
      )
    ).map(app => ({
      ...app,
      associatedClients: getAssociatedClients(app.Users?.map(user => user.id) || [])
    }));
  }, [aiAppsData.aiApps, userAIApps, isAdmin, search, selectedType, activeFilter, clients, getAssociatedClients]);

  useEffect(() => {
    console.log('filteredAIApps:', filteredAIApps.map(app => ({ id: app.id, name: app.name, updatedAt: app.updatedAt })));
  }, [filteredAIApps]);

  const handleOpenAddModal = () => {
    setSelectedAIApp(null);
    setIsAddModalOpen(true);
  };

  const handleOpenEditModal = (aiApp) => {
    // Ensure we're passing the full aiApp object
    setSelectedAIApp(aiApp);
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
    setSelectedAIApp(null);
    refreshAIApps();
  };

  const handleOpenDeleteDialog = (aiApp) => {
    setAIAppToDelete(aiApp);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setAIAppToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (aiAppToDelete) {
      try {
        await deleteAIApp(aiAppToDelete.id);
        toast.success(`AI App "${aiAppToDelete.name}" deleted successfully`);
        refreshAIApps();
      } catch (error) {
        console.error('Error deleting AI App:', error);
        toast.error(`Failed to delete AI App "${aiAppToDelete.name}". Please try again.`);
      } finally {
        handleCloseDeleteDialog();
      }
    }
  };

  const handleToggleActive = async (aiApp) => {
    try {
      await toggleAIAppActive(aiApp.id);
      toast.success(`AI App "${aiApp.name}" ${aiApp.isActive ? 'deactivated' : 'activated'} successfully`);
      refreshAIApps();
    } catch (error) {
      console.error('Error toggling AI App active state:', error);
      toast.error(`Failed to toggle AI App "${aiApp.name}" active state. Please try again.`);
    }
  };

  const handleOpenChat = (aiApp) => {
    if (aiApp.type === 'chat') {
      navigate(`/chat-app/${aiApp.id}`);
    } else if (aiApp.type === 'form') {
      navigate(`/form-app/${aiApp.id}`);
    }
  };

  return (
    <Box sx={{ 
      width: '100%', 
      minHeight: '100vh',
      bgcolor: 'background.default',
      padding: { xs: 2, sm: 3, md: 4 },
      position: 'relative',
    }}>
      <Typography variant="h4" sx={{ mb: 3 }}>AI Apps</Typography>
      
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label={isAdmin ? "Search by name, type, client, or user" : "Search by name"}
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              label="Type"
            >
              <MenuItem value="">All Types</MenuItem>
              <MenuItem value="chat">Chat</MenuItem>
              <MenuItem value="form">Form</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {isAdmin && (
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={activeFilter}
                onChange={(e) => setActiveFilter(e.target.value)}
                label="Status"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      
      {filteredAIApps.length > 0 ? (
        <Grid container spacing={3}>
          {filteredAIApps.map((aiApp, index) => {
            const key = `${aiApp.id}-${aiApp.updatedAt}-${index}`;
            console.log('Rendering AI app with key:', key);
            return (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <StyledCard sx={isAdmin ? {} : { boxShadow: 'none', bgcolor: 'transparent' }}>
                  <CardHeader>
                    <Typography variant="h6">{aiApp.name}</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <InfoChip
                        icon={<CategoryIcon fontSize="small" />}
                        label={aiApp.type}
                        size="small"
                      />
                      {isAdmin && (
                        <InfoChip
                          icon={<UpdateIcon fontSize="small" />}
                          label={aiApp.isActive ? "Active" : "Inactive"}
                          size="small"
                        />
                      )}
                    </Box>
                    <IconContainer className="actionButtons">
                      <StyledIconButton onClick={() => handleOpenChat(aiApp)}>
                        {aiApp.type === 'chat' ? <ChatIcon fontSize="small" /> : <AssignmentIcon fontSize="small" />}
                      </StyledIconButton>
                      {isAdmin && (
                        <>
                          <StyledIconButton onClick={() => handleOpenEditModal(aiApp)}>
                            <EditIcon fontSize="small" />
                          </StyledIconButton>
                          <StyledIconButton onClick={() => handleOpenDeleteDialog(aiApp)}>
                            <DeleteIcon fontSize="small" />
                          </StyledIconButton>
                        </>
                      )}
                    </IconContainer>
                  </CardHeader>
                  {isAdmin && (
                    <CardContent>
                      <Typography variant="body2" sx={{ mb: 1 }}>Associated Clients:</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
                        {aiApp.associatedClients && aiApp.associatedClients.length > 0 ? (
                          aiApp.associatedClients.map((clientId) => {
                            const client = clients.find(c => c.id === clientId);
                            return client ? (
                              <ClientChip key={client.id} label={client.name} size="small" icon={<BusinessIcon />} />
                            ) : null;
                          })
                        ) : (
                          <Typography variant="body2" color="text.secondary">No associated clients</Typography>
                        )}
                      </Box>
                      <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Assigned Users:</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {aiApp.Users && aiApp.Users.length > 0 ? (
                          aiApp.Users.map((user) => (
                            <UserChip key={user.id} label={user.name} size="small" icon={<PersonIcon />} />
                          ))
                        ) : (
                          <Typography variant="body2" color="text.secondary">No assigned users</Typography>
                        )}
                      </Box>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={aiApp.isActive}
                            onChange={() => handleToggleActive(aiApp)}
                            color="primary"
                          />
                        }
                        label={aiApp.isActive ? "Active" : "Inactive"}
                        sx={{ mt: 2 }}
                      />
                    </CardContent>
                  )}
                </StyledCard>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <NoAIAppsMessage />
        </Box>
      )}
      
      {isAdmin && (
        <AddButton color="primary" aria-label="add" onClick={handleOpenAddModal}>
          <AddIcon />
        </AddButton>
      )}

      {isAdmin && (
        <AddEditAIApps
          open={isAddModalOpen}
          handleClose={handleCloseAddModal}
          editingAppId={selectedAIApp ? selectedAIApp.id : null}
          onSuccess={refreshAIApps}
        />
      )}

      {isAdmin && (
        <DeleteConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
          itemName={aiAppToDelete?.name}
          itemType="AI App"
        />
      )}
    </Box>
  );
};

export default AIPage;