import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper, 
  CircularProgress, 
  styled, 
  alpha,
  Divider
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useConversation } from '../../contexts/ConversationContext';
import { format, isToday, isYesterday, isSameDay, parseISO } from 'date-fns'; // Make sure to install this package
import { toast } from 'react-toastify'; // Make sure to install this package

const StyledPaper = styled(Paper)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(10), // Add extra padding at the bottom for the floating input
}));

const MessageBubble = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUser',
})(({ theme, isUser }) => ({
  maxWidth: '70%',
  padding: theme.spacing(1, 2),
  borderRadius: 16,
  backgroundColor: isUser ? theme.palette.primary.main : theme.palette.grey[200],
  color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  marginBottom: theme.spacing(3), // Increase bottom margin to accommodate timestamp
  position: 'relative',
  minWidth: '80px', // Add a minimum width to accommodate short messages
}));

const MessageTimestamp = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: -20,
  left: 0, // Align to the left instead of right
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap', // Prevent timestamp from wrapping
}));

const FloatingInputContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 28,
  boxShadow: theme.shadows[3],
}));

const DateDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  '& .MuiDivider-wrapper': {
    padding: theme.spacing(0, 2),
  },
}));

const MessageTime = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isUser',
})(({ theme, isUser }) => ({
  fontSize: '0.75rem',
  color: isUser ? alpha(theme.palette.common.white, 0.7) : theme.palette.text.secondary,
  marginTop: theme.spacing(0.5),
}));

const ChatInterface = React.memo(({ conversation, aiAppId }) => {
  const [message, setMessage] = useState('');
  const [isAITyping, setIsAITyping] = useState(false);
  const [localMessages, setLocalMessages] = useState([]);
  const { createMessage, fetchMessages, messages, loading } = useConversation();
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const fetchMessagesData = useCallback(async () => {
    if (conversation && conversation.id) {
      await fetchMessages(conversation.id);
    }
  }, [conversation, fetchMessages]);

  useEffect(() => {
    fetchMessagesData();
  }, [fetchMessagesData]);

  useEffect(() => {
    if (messages[conversation.id]) {
      setLocalMessages(messages[conversation.id]);
    }
  }, [messages, conversation.id]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatContainerRef.current) {
        const { scrollHeight, clientHeight } = chatContainerRef.current;
        chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
      }
    };

    if (localMessages.length > 0) {
      scrollToBottom();
    }
  }, [localMessages, isAITyping]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (message.trim()) {
      const trimmedMessage = message.trim();
      setMessage('');
      
      setIsAITyping(true);
      try {
        const userMessage = { content: trimmedMessage, role: 'user', timestamp: new Date() };
        setLocalMessages(prev => [...prev, userMessage]);

        const response = await createMessage(conversation.id, trimmedMessage, 'user', aiAppId, conversation.workflow); // Pass the workflow
        if (response.error) {
          toast.error(response.error);
          return;
        }
        if (response.aiMessage) {
          setLocalMessages(prev => [...prev.filter(msg => msg.content !== trimmedMessage), response.userMessage, response.aiMessage]);
        }
      } catch (error) {
        console.error('Error sending message:', error);
        toast.error('Failed to send message. Please try again.');
      } finally {
        setIsAITyping(false);
      }
    }
  };

  const groupMessagesByDate = useCallback((messages) => {
    const grouped = {};
    messages.forEach(msg => {
      let date;
      try {
        date = msg.timestamp instanceof Date ? msg.timestamp : parseISO(msg.timestamp);
      } catch (error) {
        console.error('Invalid timestamp:', msg.timestamp);
        date = new Date(); // Fallback to current date if parsing fails
      }
      const dateKey = format(date, 'yyyy-MM-dd');
      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }
      grouped[dateKey].push({...msg, timestamp: date});
    });
    return grouped;
  }, []);

  const formatDateDivider = useCallback((dateString) => {
    try {
      const date = parseISO(dateString);
      if (isToday(date)) return 'Today';
      if (isYesterday(date)) return 'Yesterday';
      return format(date, 'MMMM d, yyyy');
    } catch (error) {
      console.error('Invalid date string:', dateString);
      return 'Unknown Date';
    }
  }, []);

  const formatMessageTime = useCallback((timestamp) => {
    try {
      return format(timestamp, 'h:mm a');
    } catch (error) {
      console.error('Invalid timestamp:', timestamp);
      return '';
    }
  }, []);

  if (!conversation) {
    return <Typography>Select a conversation to start chatting</Typography>;
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <StyledPaper ref={chatContainerRef}>
        {loading && localMessages.length === 0 ? (
          <CircularProgress sx={{ alignSelf: 'center', m: 'auto' }} />
        ) : (
          <>
            {Object.entries(groupMessagesByDate(localMessages)).map(([dateKey, messages]) => (
              <React.Fragment key={dateKey}>
                <DateDivider>
                  <Typography variant="body2">
                    {formatDateDivider(dateKey)}
                  </Typography>
                </DateDivider>
                {messages.map((msg, index) => (
                  <MessageBubble key={index} isUser={msg.role === 'user'}>
                    <Typography variant="body1">{msg.content}</Typography>
                    <MessageTime isUser={msg.role === 'user'}>
                      {formatMessageTime(msg.timestamp)}
                    </MessageTime>
                  </MessageBubble>
                ))}
              </React.Fragment>
            ))}
            {isAITyping && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, alignSelf: 'flex-start' }}>
                <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>AI is typing</Typography>
                <CircularProgress size={20} />
              </Box>
            )}
            <div ref={messagesEndRef} />
          </>
        )}
      </StyledPaper>
      <FloatingInputContainer>
        <form onSubmit={handleSendMessage} style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <TextField
            fullWidth
            variant="standard"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
            disabled={isAITyping}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              flexGrow: 1,
              '& .MuiInputBase-root': {
                padding: '10px 16px',
              },
            }}
          />
          <Button 
            type="submit" 
            variant="contained" 
            endIcon={<SendIcon />} 
            sx={{ borderRadius: 28, minWidth: 100, m: 1 }} 
            disabled={isAITyping}
          >
            Send
          </Button>
        </form>
      </FloatingInputContainer>
    </Box>
  );
});

export default ChatInterface;
