import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import api from '../services/api';

const RoleContext = createContext();

export function RoleProvider({ children }) {
  const [roles, setRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchRoles = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get('/roles');
      setRoles(data);
      // Now you're explicitly handling the department data
      // You can access department info for each role if needed
    } catch (error) {
      setError(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchRole = useCallback(async (id) => {
    if (typeof id !== 'string' && typeof id !== 'number') {
      throw new Error('Invalid ID provided for role fetch');
    }
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get(`/roles/${id}`);
      setCurrentRole(data);
      // Now you're explicitly handling the department data
      // You can access role.department if needed
      return data;
    } catch (error) {
      if (error.response?.status === 404) {
        setError('Role not found');
      } else {
        setError(error.response?.data?.error || error.message);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const addRole = async (roleData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post('/roles', roleData);
      setRoles(prevRoles => [...prevRoles, data]);
      return data;
    } catch (error) {
      setError(error.response?.data?.errors?.[0]?.msg || error.response?.data?.error || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateRole = async (id, roleData) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for role update');
    }
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.put(`/roles/${id}`, roleData);
      setRoles(prevRoles => prevRoles.map(role => role.id === id ? data : role));
      if (currentRole && currentRole.id === id) {
        setCurrentRole(data);
      }
      return data;
    } catch (error) {
      if (error.response?.status === 400) {
        setError(error.response.data.errors || error.response.data.error);
      } else if (error.response?.status === 404) {
        setError('Role not found');
      } else {
        setError(error.response?.data?.error || error.message);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteRole = async (id) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for role deletion');
    }
    setLoading(true);
    setError(null);
    try {
      await api.delete(`/roles/${id}`);
      setRoles(prevRoles => prevRoles.filter(role => role.id !== id));
      if (currentRole && currentRole.id === id) {
        setCurrentRole(null);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        setError('Role not found');
      } else {
        setError(error.response?.data?.error || error.message);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <RoleContext.Provider value={{ 
      roles, 
      currentRole, 
      loading, 
      error, 
      fetchRoles, 
      fetchRole, 
      addRole, 
      updateRole, 
      deleteRole 
    }}>
      {children}
    </RoleContext.Provider>
  );
}

export function useRoles() {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error('useRoles must be used within a RoleProvider');
  }
  return context;
}