import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import api from '../services/api';

const DepartmentContext = createContext();

export function DepartmentProvider({ children }) {
  const [departments, setDepartments] = useState([]);
  const [currentDepartment, setCurrentDepartment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDepartments = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get('/departments');
      setDepartments(data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDepartment = useCallback(async (id) => {
    if (typeof id !== 'string' && typeof id !== 'number') {
      throw new Error('Invalid ID provided for department fetch');
    }
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get(`/departments/${id}`);
      setCurrentDepartment(data);
      return data;
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  const addDepartment = async (departmentData) => {
    setLoading(true);
    setError(null);
    try {
      const { data: newDepartment } = await api.post('/departments', departmentData);
      setDepartments(prevDepartments => [...prevDepartments, newDepartment]);
      return newDepartment;
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateDepartment = async (id, departmentData) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for department update');
    }
    setLoading(true);
    setError(null);
    try {
      const { data: updatedDepartment } = await api.put(`/departments/${id}`, departmentData);
      setDepartments(prevDepartments => prevDepartments.map(department => department.id === id ? updatedDepartment : department));
      if (currentDepartment && currentDepartment.id === id) {
        setCurrentDepartment(updatedDepartment);
      }
      return updatedDepartment;
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteDepartment = async (id) => {
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for department deletion');
    }
    setLoading(true);
    setError(null);
    try {
      await api.delete(`/departments/${id}`);
      setDepartments(prevDepartments => prevDepartments.filter(department => department.id !== id));
      if (currentDepartment && currentDepartment.id === id) {
        setCurrentDepartment(null);
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <DepartmentContext.Provider value={{ 
      departments, 
      currentDepartment,
      loading, 
      error, 
      fetchDepartments,
      fetchDepartment,
      addDepartment, 
      updateDepartment, 
      deleteDepartment 
    }}>
      {children}
    </DepartmentContext.Provider>
  );
}

export function useDepartments() {
  const context = useContext(DepartmentContext);
  if (!context) {
    throw new Error('useDepartments must be used within a DepartmentProvider');
  }
  return context;
}