import React, { createContext, useContext, useState, useCallback } from 'react';
import api from '../services/api';
import { useAIApp } from './AIAppContext';

const FormSubmissionContext = createContext();

export const useFormSubmission = () => {
  const context = useContext(FormSubmissionContext);
  if (!context) {
    throw new Error('useFormSubmission must be used within a FormSubmissionProvider');
  }
  return context;
};

export const FormSubmissionProvider = ({ children }) => {
  const [formSubmissions, setFormSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { runAIApp } = useAIApp();

  const fetchFormSubmissionsByUser = useCallback(async (userId, params = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/form-submissions/user/${userId}`, { params });
      setFormSubmissions(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching user form submissions:', error);
      setError(error.response?.data?.error || 'Failed to fetch user form submissions');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const createFormSubmission = useCallback(async (aiAppId, inputData, inputMetadata, outputMetadata, name, runImmediately = false) => {
    setError(null);
    try {
      const response = await api.post('/form-submissions', { 
        aiAppId, 
        inputData, 
        inputMetadata, 
        outputMetadata, 
        name, 
        runImmediately 
      });
      
      if (!runImmediately && response.data.outputData) {
        console.warn('Received unexpected outputData for a new submission that was not run immediately');
        const { outputData, ...submissionWithoutOutput } = response.data;
        setFormSubmissions(prev => [submissionWithoutOutput, ...prev]);
        return submissionWithoutOutput;
      }

      setFormSubmissions(prev => [response.data, ...prev]);
      return response.data;
    } catch (error) {
      console.error('Error creating form submission:', error);
      setError(error.response?.data?.error || 'Failed to create form submission');
      throw error;
    }
  }, []);

  const archiveFormSubmission = useCallback(async (id) => {
    setLoading(true);
    setError(null);
    try {
      await api.patch(`/form-submissions/${id}/archive`);
      setFormSubmissions(prev => prev.map(submission => 
        submission.id === id ? { ...submission, isArchived: true } : submission
      ));
    } catch (error) {
      console.error('Error archiving form submission:', error);
      setError(error.response?.data?.error || 'Failed to archive form submission');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const unarchiveFormSubmission = useCallback(async (id) => {
    setLoading(true);
    setError(null);
    try {
      await api.patch(`/form-submissions/${id}/unarchive`);
      setFormSubmissions(prev => prev.map(submission => 
        submission.id === id ? { ...submission, isArchived: false } : submission
      ));
    } catch (error) {
      console.error('Error unarchiving form submission:', error);
      setError(error.response?.data?.error || 'Failed to unarchive form submission');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const renameFormSubmission = useCallback(async (id, name) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.patch(`/form-submissions/${id}/rename`, { name });
      setFormSubmissions(prev => prev.map(submission => 
        submission.id === id ? response.data.submission : submission
      ));
    } catch (error) {
      console.error('Error renaming form submission:', error);
      setError(error.response?.data?.error || 'Failed to rename form submission');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const updateFormSubmissionMetadata = useCallback(async (id, inputMetadata, outputMetadata) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.patch(`/form-submissions/${id}/metadata`, { inputMetadata, outputMetadata });
      setFormSubmissions(prev => prev.map(submission => 
        submission.id === id ? response.data : submission
      ));
      return response.data;
    } catch (error) {
      console.error('Error updating form submission metadata:', error);
      setError(error.response?.data?.error || 'Failed to update form submission metadata');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const rerunFormSubmission = useCallback(async (id, inputData, inputMetadata, outputMetadata, workflow) => {
    setError(null);
    try {
      const response = await api.put(`/form-submissions/${id}/rerun`, { inputData, inputMetadata, outputMetadata, workflow });
      setFormSubmissions(prev => {
        const updatedSubmissions = prev.map(submission => 
          submission.id === id ? response.data : submission
        );
        return updatedSubmissions.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      });
      return response.data;
    } catch (error) {
      console.error('Error rerunning form submission:', error);
      setError(error.response?.data?.error || 'Failed to rerun form submission');
      throw error;
    }
  }, []);

  const getFormSubmission = useCallback(async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/form-submissions/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching form submission:', error);
      setError(error.response?.data?.error || 'Failed to fetch form submission');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    formSubmissions,
    loading,
    error,
    fetchFormSubmissionsByUser,
    createFormSubmission,
    archiveFormSubmission,
    unarchiveFormSubmission,
    renameFormSubmission,
    rerunFormSubmission,
    getFormSubmission,
    updateFormSubmissionMetadata,
  };

  return (
    <FormSubmissionContext.Provider value={value}>
      {children}
    </FormSubmissionContext.Provider>
  );
};
