import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, IconButton, CircularProgress, Typography, Alert } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChatConversationList from './ChatConversationList';
import ChatInterface from './ChatInterface';
import { useConversation } from '../../contexts/ConversationContext';
import { useUser } from '../../contexts/UserContext';
import { useAIApp } from '../../contexts/AIAppContext';

const ChatApp = React.memo(() => {
  console.log('ChatApp rendering');
  const { aiAppId, conversationId } = useParams();
  const navigate = useNavigate();
  const { user } = useUser();
  const { conversations, fetchConversations, createConversation, loading: conversationsLoading, resetConversations } = useConversation();
  const { resetAIApps, loading: aiAppLoading, checkUserAccess } = useAIApp();
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [sideMenuOpen, setSideMenuOpen] = useState(true);
  const [error, setError] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);

  const fetchConversationsData = useCallback(async () => {
    if (user && aiAppId) {
      try {
        console.log('Fetching conversations...');
        const access = await checkUserAccess(aiAppId, user.id);
        setHasAccess(access);
        if (access) {
          await fetchConversations(user.id, aiAppId);
          console.log('Conversations fetched successfully');
        } else {
          setError('You do not have access to this AI app.');
        }
      } catch (error) {
        console.error('Error fetching conversations:', error);
        setError('Failed to fetch conversations. Please try again.');
      }
    }
  }, [user, aiAppId, fetchConversations, checkUserAccess]);

  useEffect(() => {
    console.log('Running fetchConversationsData effect');
    fetchConversationsData();
  }, [fetchConversationsData]);

  const handleSelectConversation = useCallback((conversation) => {
    setSelectedConversationId(conversation.id);
    navigate(`/chat-app/${aiAppId}/${conversation.id}`);
  }, [aiAppId, navigate]);

  const handleCreateNewConversation = useCallback(async () => {
    try {
      const newConversation = await createConversation(aiAppId, 'New Conversation');
      setSelectedConversationId(newConversation.id);
      navigate(`/chat-app/${aiAppId}/${newConversation.id}`);
      await fetchConversationsData();
      // Select the newly created conversation
      setSelectedConversationId(newConversation.id);
    } catch (error) {
      console.error('Error creating new conversation:', error);
      setError('Failed to create a new conversation. Please try again.');
    }
  }, [aiAppId, createConversation, navigate, fetchConversationsData]);

  const selectedConversation = useMemo(() => 
    conversations.find(c => c.id === selectedConversationId),
    [conversations, selectedConversationId]
  );

  const toggleSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };

  const hardRefresh = useCallback(async () => {
    try {
      resetAIApps();
      resetConversations();
      await fetchConversationsData();
    } catch (error) {
      console.error('Error during hard refresh:', error);
      setError('Failed to refresh data. Please try again.');
    }
  }, [resetAIApps, resetConversations, fetchConversationsData]);

  useEffect(() => {
    hardRefresh();
  }, [hardRefresh]);

  useEffect(() => {
    if (conversations.length > 0 && !selectedConversationId) {
      const firstConversation = conversations[0];
      setSelectedConversationId(firstConversation.id);
      navigate(`/chat-app/${aiAppId}/${firstConversation.id}`);
    }
  }, [conversations, selectedConversationId, aiAppId, navigate]);

  console.log('Rendering ChatApp component');
  console.log('aiAppLoading:', aiAppLoading);
  console.log('conversationsLoading:', conversationsLoading);
  console.log('Conversations:', conversations);
  console.log('Selected conversation:', selectedConversation);

  if (aiAppLoading) {
    console.log('Rendering loading state');
    return <CircularProgress />;
  }

  if (error) {
    console.log('Rendering error state');
    return <Alert severity="error">{error}</Alert>;
  }

  if (!hasAccess) {
    return <Alert severity="warning">You do not have access to this AI app.</Alert>;
  }

  if (conversationsLoading && conversations.length === 0) {
    console.log('Rendering conversations loading state');
    return <CircularProgress />;
  }

  console.log('Rendering full ChatApp component');

  return (
    <Box sx={{ height: 'calc(100vh - 64px)', display: 'flex', overflow: 'hidden' }}>
      <Box
        sx={{
          width: sideMenuOpen ? '300px' : '0px',
          height: '100%',
          overflow: 'hidden',
          transition: 'width 0.3s',
          flexShrink: 0,
          bgcolor: 'background.paper',
          borderRight: 1,
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ChatConversationList
          conversations={conversations}
          selectedConversationId={selectedConversationId}
          onSelectConversation={handleSelectConversation}
          onCreateNewConversation={handleCreateNewConversation}
        />
      </Box>
      <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}>
          <IconButton onClick={toggleSideMenu}>
            <MenuIcon />
          </IconButton>
        </Box>
        {selectedConversation ? (
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            <ChatInterface
              key={selectedConversation.id}
              conversation={selectedConversation}
              aiAppId={aiAppId}
            />
          </Box>
        ) : (
          <Typography sx={{ p: 2 }}>No conversation selected. Create a new one or select from the list.</Typography>
        )}
      </Box>
    </Box>
  );
});

export default ChatApp;
