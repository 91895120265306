import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import RobotIcon from '@mui/icons-material/SmartToy';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[3],
}));

const AnimatedRobot = styled(RobotIcon)(({ theme }) => ({
  fontSize: '5rem',
  color: theme.palette.primary.main,
  animation: '$wave 2s infinite',
  '@keyframes wave': {
    '0%': { transform: 'rotate(0deg)' },
    '15%': { transform: 'rotate(-15deg)' },
    '30%': { transform: 'rotate(15deg)' },
    '45%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
}));

const NoAIAppsMessage = () => {
  return (
    <StyledPaper elevation={3}>
      <AnimatedRobot />
      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        Oops! No AI Apps Found
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Looks like our AI is taking a coffee break! ☕️
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
        Check back later for some exciting new AI apps.
      </Typography>
      <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
        In the meantime, why not teach a robot to dance? 🕺🤖
      </Typography>
    </StyledPaper>
  );
};

export default NoAIAppsMessage;

