import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import api from '../services/api';

const ClientContext = createContext();

export function ClientProvider({ children }) {
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchClients = useCallback(async () => {
    try {
      const response = await api.get('/clients');
      const activeClients = response.data.filter(client => !client.isDeleted);
      setClients(activeClients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  }, []);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await api.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, []);

  useEffect(() => {
    fetchClients();
    fetchUsers();
  }, [fetchClients, fetchUsers]);

  const addClient = async (clientData) => {
    try {
      const response = await api.post('/clients', clientData);
      const newClient = response.data;
      setClients(prevClients => [...prevClients, newClient]);
      return newClient;
    } catch (error) {
      console.error('Error adding client:', error);
      throw error.response ? error.response.data : error;
    }
  };

  const updateClient = async (id, clientData) => {
    try {
      const response = await api.put(`/clients/${id}`, clientData);
      const updatedClient = response.data;
      setClients(prevClients => prevClients.map(client => 
        client.id === id ? updatedClient : client
      ));
      return updatedClient;
    } catch (error) {
      console.error('Error updating client:', error);
      throw error.response ? error.response.data : error;
    }
  };

  const deleteClient = async (id) => {
    try {
      await api.delete(`/clients/${id}`);
      setClients(prevClients => prevClients.filter(client => client.id !== id));
    } catch (error) {
      console.error('Error deleting client:', error);
      throw error.response ? error.response.data : error;
    }
  };

  const updateClientApiKey = async (clientId, apiKey) => {
    try {
      const response = await api.patch(`/clients/${clientId}/api-key`, { apiKey });
      const updatedClient = response.data;
      setClients(prevClients => prevClients.map(client => 
        client.id === clientId ? { ...client, apiKey: updatedClient.apiKey } : client
      ));
      return updatedClient;
    } catch (error) {
      console.error('Error updating client API key:', error);
      const errorMessage = error.response?.data?.error || error.message || 'An unknown error occurred';
      throw new Error(`Failed to update API key: ${errorMessage}`);
    }
  };

  const getAssociatedClients = useCallback((userIds) => {
    if (!userIds || !Array.isArray(userIds)) {
      return []; // Return an empty array if userIds is not valid
    }
    const associatedClientIds = new Set();
    userIds.forEach(userId => {
      const user = users.find(u => u.id === userId);
      if (user && user.clientId) {
        associatedClientIds.add(user.clientId);
      }
    });
    return Array.from(associatedClientIds);
  }, [users]);

  return (
    <ClientContext.Provider value={{ 
      clients, 
      addClient, 
      updateClient, 
      deleteClient, 
      fetchClients,
      updateClientApiKey,
      getAssociatedClients
    }}>
      {children}
    </ClientContext.Provider>
  );
}

export function useClients() {
  return useContext(ClientContext);
}
