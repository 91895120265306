import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Alert, CircularProgress } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAIApp } from '../../contexts/AIAppContext';
import { useUser } from '../../contexts/UserContext';
import { useFormSubmission } from '../../contexts/FormSubmissionContext';
import FormConversationList from './FormConversationList';
import FormInterface from './FormInterface';

const FormAIApp = () => {
  const { aiAppId, formSubmissionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchAIApp, checkUserAccess } = useAIApp();
  const { user } = useUser();
  const { fetchFormSubmissionsByUser, formSubmissions, getFormSubmission, archiveFormSubmission } = useFormSubmission();
  const [aiApp, setAIApp] = useState(null);
  const [selectedFormSubmission, setSelectedFormSubmission] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  const loadAIApp = useCallback(async () => {
    setLoading(true);
    try {
      const app = await fetchAIApp(aiAppId);
      setAIApp(app);
      const access = await checkUserAccess(aiAppId, user.id);
      setHasAccess(access);
      if (access) {
        await fetchFormSubmissionsByUser(user.id, { aiAppId });
      } else {
        setError('You do not have access to this AI app.');
      }
    } catch (error) {
      console.error('Error loading AI app data:', error);
      setError('Failed to load AI app data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [aiAppId, fetchAIApp, checkUserAccess, user.id, fetchFormSubmissionsByUser]);

  useEffect(() => {
    loadAIApp();
  }, [loadAIApp]);

  const checkAccess = useCallback(async () => {
    try {
      // Instead of using checkUserAccess, we'll use the user object directly
      const isAdmin = user.permission === 'admin';
      const hasPermission = isAdmin || (aiApp && aiApp.Users.some(u => u.id === user.id));
      setHasAccess(hasPermission);
    } catch (error) {
      console.error('Error checking access for form app:', error);
      setHasAccess(false);
    }
  }, [user, aiApp]);

  useEffect(() => {
    checkAccess();
  }, [checkAccess]);

  useEffect(() => {
    const loadSelectedSubmission = async () => {
      if (formSubmissionId) {
        try {
          const submission = await getFormSubmission(formSubmissionId);
          setSelectedFormSubmission(submission);
        } catch (error) {
          console.error('Error loading selected submission:', error);
          setError('Failed to load selected submission. Please try again later.');
        }
      } else if (formSubmissions.length > 0) {
        // Sort formSubmissions by updatedAt in descending order and filter out archived ones
        const sortedSubmissions = formSubmissions
          .filter(submission => !submission.isArchived)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

        if (sortedSubmissions.length > 0) {
          const latestSubmission = sortedSubmissions[0];
          setSelectedFormSubmission(latestSubmission);
          navigate(`/form-app/${aiAppId}/${latestSubmission.id}`, { replace: true });
        } else {
          // If all submissions are archived, set selectedFormSubmission to null
          setSelectedFormSubmission(null);
          navigate(`/form-app/${aiAppId}/new`, { replace: true });
        }
      }
    };

    loadSelectedSubmission();
  }, [formSubmissions, formSubmissionId, aiAppId, navigate, getFormSubmission]);

  const handleSelectFormSubmission = useCallback((submission) => {
    if (submission === 'new') {
      setSelectedFormSubmission(null);
      navigate(`/form-app/${aiAppId}/new`, { replace: true });
    } else {
      setSelectedFormSubmission(submission);
      navigate(`/form-app/${aiAppId}/${submission.id}`, { replace: true });
    }
  }, [aiAppId, navigate]);

  const handleArchive = useCallback(async (submissionId) => {
    try {
      await archiveFormSubmission(submissionId);
      
      // After archiving, select the next available submission
      const remainingSubmissions = formSubmissions.filter(s => !s.isArchived && s.id !== submissionId);
      if (remainingSubmissions.length > 0) {
        const nextSubmission = remainingSubmissions[0];
        handleSelectFormSubmission(nextSubmission);
      } else {
        // If no submissions left, navigate to new submission
        handleSelectFormSubmission('new');
      }
    } catch (error) {
      console.error('Error archiving submission:', error);
      setError('Failed to archive submission. Please try again.');
    }
  }, [formSubmissions, archiveFormSubmission, handleSelectFormSubmission]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!hasAccess) {
    return <Alert severity="warning">You do not have access to this AI app.</Alert>;
  }

  return (
    <Box sx={{ flexGrow: 1, height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} md={4} sx={{ height: '100%', overflowY: 'auto', borderRight: 1, borderColor: 'divider' }}>
          <FormConversationList
            aiAppId={aiAppId}
            userId={user.id}
            onSelectFormSubmission={handleSelectFormSubmission}
            selectedFormSubmissionId={selectedFormSubmission?.id}
            onArchive={handleArchive}
          />
        </Grid>
        <Grid item xs={12} md={8} sx={{ height: '100%', overflowY: 'auto' }}>
          <FormInterface
            aiApp={aiApp}
            selectedFormSubmission={selectedFormSubmission}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(FormAIApp);
