import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { login as apiLogin, fetchCurrentUser } from '../services/api';
import api from '../services/api';  // Add this import
import Cookies from 'js-cookie';  // Add this import
import { jwtDecode } from 'jwt-decode';  // Change this line

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);  // Add this state
  const [loading, setLoading] = useState(true);  // Add this line
  const [authLoading, setAuthLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hideTime, setHideTime] = useState(false);  // Add this state

  const checkAuth = useCallback(async () => {
    setAuthLoading(true);
    try {
      const token = Cookies.get('token');
      if (token) {
        const decodedToken = jwtDecode(token);  // Change this line
        const userData = await fetchCurrentUser();
        setUser({ ...userData, permission: decodedToken.permission });  // Include permission from token
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Error checking auth:', error);
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setAuthLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    const handleUnauthorized = () => {
      setUser(null);
      setIsAuthenticated(false);
    };
    window.addEventListener('unauthorized', handleUnauthorized);
    return () => window.removeEventListener('unauthorized', handleUnauthorized);
  }, []);

  const login = async (email, password) => {
    setAuthLoading(true);
    try {
      const { token, user } = await apiLogin(email, password);
      const decodedToken = jwtDecode(token);
      const completeUserData = { ...user, permission: decodedToken.permission };
      setUser(completeUserData);
      setIsAuthenticated(true);
      Cookies.set('token', token);
      return { token, user: completeUserData };
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    } finally {
      setAuthLoading(false);
    }
  };

  // Add these new functions
  const fetchUserAIApps = useCallback(async (userId) => {
    try {
      const response = await api.get(`/users/${userId}/ai-apps`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user AI apps:', error);
      throw error;
    }
  }, []);

  const assignAIAppToUser = async (userId, aiAppId) => {
    try {
      await api.post(`/ai-apps/${aiAppId}/users/${userId}`);
      // Optionally update the local state if needed
    } catch (error) {
      console.error('Error assigning AI app to user:', error);
      throw error;
    }
  };

  const updateUserAIApps = async (userId, aiAppIds) => {
    try {
      const response = await api.put(`/users/${userId}/ai-apps`, { aiAppIds });
      if (user && user.id === userId) {
        setUser(prevUser => ({ ...prevUser, aiApps: response.data }));
      }
      return response.data;
    } catch (error) {
      console.error('Error updating user AI apps:', error);
      throw error;
    }
  };

  const toggleTimeHide = useCallback(async (userId) => {
    try {
      const response = await api.patch(`/users/${userId}/toggle-time-hide`);
      const updatedUser = response.data;

      setUsers(prevUsers => prevUsers.map(u => u.id === userId ? updatedUser : u));
      if (user && user.id === userId) {
        setUser(updatedUser);
      }

      return updatedUser;
    } catch (error) {
      console.error('Error toggling time hide:', error);
      throw error;
    }
  }, [user]);

  const toggleHideTime = useCallback(() => {
    setHideTime(prevHideTime => !prevHideTime);
  }, []);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/users?include=role');
      // Filter out deleted users
      const activeUsers = response.data.filter(user => !user.isDeleted);
      setUsers(activeUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const addUser = async (userData) => {
    try {
      const response = await api.post('/users', userData);
      // Only add the new user if it's not deleted
      if (!response.data.isDeleted) {
        setUsers(prevUsers => [...prevUsers, response.data]);
      }
      return response.data;
    } catch (error) {
      console.error('Error adding user:', error);
      throw error;
    }
  };

  const updateUser = async (userId, userData) => {
    try {
      const response = await api.put(`/users/${userId}`, userData);
      setUsers(prevUsers => prevUsers.map(u => u.id === userId ? response.data : u)
                                     .filter(u => !u.isDeleted));
      if (user && user.id === userId) {
        setUser(response.data);
      }
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };

  const deleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      setUsers(prevUsers => prevUsers.filter(u => u.id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  };

  const checkUserPermission = (requiredPermission) => {
    return user && user.permission === requiredPermission;
  };

  const changeUserRole = async (userId, newRoleId) => {
    try {
      const response = await api.put(`/users/${userId}`, { roleId: newRoleId });
      setUsers(users.map(u => u.id === userId ? response.data : u));
      if (user && user.id === userId) {
        setUser(response.data);
      }
      return response.data;
    } catch (error) {
      console.error('Error changing user role:', error);
      throw error;
    }
  };

  const checkUserRole = (requiredRoles) => {
    return user && requiredRoles.includes(user.role);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUsers();
    }
  }, [isAuthenticated, fetchUsers]);

  const logout = useCallback(() => {
    Cookies.remove('token');
    setUser(null);
    setIsAuthenticated(false);
  }, []);

  const fetchAllUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/users/all');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching all users:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    user,
    users,
    loading,
    fetchUsers,
    fetchAllUsers,
    addUser,
    updateUser,
    deleteUser,
    login,
    logout,
    checkUserPermission,
    changeUserRole,
    toggleTimeHide,
    checkAuth,
    isAuthenticated,
    authLoading,
    hideTime,
    toggleHideTime,
    checkUserRole,
    fetchUserAIApps,
    assignAIAppToUser,
    updateUserAIApps,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}
