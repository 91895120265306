import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import api from '../services/api';

const PortfolioProjectContext = createContext();

export function PortfolioProjectProvider({ children }) {
  const [projects, setProjects] = useState([]);  // Initialize as an empty array
  const [currentProject, setCurrentProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchProjects = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get('/portfolio-projects');
      // Filter out any null projects
      setProjects((data || []).filter(project => project != null));
    } catch (error) {
      console.error('Error fetching portfolio projects:', error);
      setError(error.response?.data?.message || error.message);
      setProjects([]);
    } finally {
      setLoading(false);
      setIsLoaded(true);
    }
  }, []);

  const fetchProject = useCallback(async (id) => {
    if (typeof id !== 'string' && typeof id !== 'number') {
      throw new Error('Invalid ID provided for project fetch');
    }
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get(`/portfolio-projects/${id}`);
      setCurrentProject(data);
      return data;
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const addProject = async (projectData) => {
    setLoading(true);
    setError(null);
    try {
      console.log('Sending project data:', JSON.stringify(projectData, null, 2));
      const { data: newProject } = await api.post('/portfolio-projects', projectData);
      setProjects(prevProjects => [...prevProjects, newProject]);
      await fetchProjects(); // Fetch all projects to ensure consistency
      return newProject;
    } catch (error) {
      console.error('Error adding project:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateProject = async (id, projectData) => {
    console.log('Updating project with id:', id);
    console.log('Project data:', JSON.stringify(projectData, null, 2));
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      console.error('Invalid ID type:', typeof id);
      throw new Error('Invalid ID provided for project update');
    }
    setLoading(true);
    setError(null);
    try {
      const { data: updatedProject } = await api.put(`/portfolio-projects/${id}`, projectData);
      console.log('Updated project:', updatedProject);
      setProjects(prevProjects => prevProjects.map(project => project.id === id ? updatedProject : project));
      if (currentProject && currentProject.id === id) {
        setCurrentProject(updatedProject);
      }
      await fetchProjects(); // Fetch all projects to ensure consistency
      return updatedProject;
    } catch (error) {
      console.error('Error updating project:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteProject = async (id) => {
    setLoading(true);
    setError(null);
    try {
      await api.delete(`/portfolio-projects/${id}`);
      setProjects(prevProjects => prevProjects.filter(project => project.id !== id));
      if (currentProject && currentProject.id === id) {
        setCurrentProject(null);
      }
      // After successful deletion, fetch the projects again to ensure consistency
      await fetchProjects();
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <PortfolioProjectContext.Provider value={{ 
      projects, 
      currentProject, 
      loading, 
      error, 
      isLoaded,
      fetchProjects, 
      fetchProject, 
      addProject, 
      updateProject, 
      deleteProject 
    }}>
      {children}
    </PortfolioProjectContext.Provider>
  );
}

export function usePortfolioProjects() {
  const context = useContext(PortfolioProjectContext);
  if (!context) {
    throw new Error('usePortfolioProjects must be used within a PortfolioProjectProvider');
  }
  return context;
}