import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams, useNavigate } from 'react-router-dom';
import { UserProvider, useUser } from './contexts/UserContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress } from '@mui/material';
import { ClientProvider } from './contexts/ClientContext';
import { ProjectProvider } from './contexts/ProjectContext';
import { HoursProvider } from './contexts/HoursContext';
import { RetainerProvider } from './contexts/RetainerContext';
import { RoleProvider } from './contexts/RoleContext';
import { DepartmentProvider } from './contexts/DepartmentContext';
import { ValueChainProvider } from './contexts/ValueChainContext';
import { ServicesProvider } from './contexts/ServicesContext';
import { TagsProvider } from './contexts/TagsContext';
import { PortfolioProjectProvider } from './contexts/PortfolioProjectContext';
import ProtectedRoute from './components/ProtectedRoute';
import NavBar from './components/NavBar';
import { AssetTypeProvider } from './contexts/AssetTypeContext';
import { AIAppProvider } from './contexts/AIAppContext';
import AIPage from './components/AI/AIPage';
import { ConversationProvider } from './contexts/ConversationContext';
import ChatApp from './components/AI/ChatApp';
import { FormSubmissionProvider } from './contexts/FormSubmissionContext';
import FormAIApp from './components/AI/FormAIApp';

// Lazy load components
const Login = lazy(() => import('./components/Login'));
const Dashboard = lazy(() => import('./components/Time/Dashboard'));
const LogHours = lazy(() => import('./components/Time/LogHours'));
const Time = lazy(() => import('./components/Time/Time'));
const ManageDepartmentsRoles = lazy(() => import('./components/Time/ManageDepartmentsRoles'));
const ValueChainList = lazy(() => import('./components/ValueChain/ValueChainList'));
const ValueChainCreator = lazy(() => import('./components/ValueChain/ValueChainCreator'));
const ValueChainView = lazy(() => import('./components/ValueChain/ValueChainView'));
const Portfolio = lazy(() => import('./components/Portfolio/Portfolio'));
const AdminPage = lazy(() => import('./components/Admin/AdminPage'));

const theme = createTheme();

function AppContent() {
  const { checkAuth, authLoading, user, isAuthenticated } = useUser();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (authLoading) {
    return <CircularProgress />;
  }

  const getDefaultRoute = () => {
    if (!isAuthenticated) return '/login';
    switch (user?.permission) {
      case 'member':
        return '/log-hours';
      case 'admin':
      case 'manager':
        return '/time';
      case 'client':
        return '/portfolio';
      default:
        return '/login';
    }
  };

  return (
    <ServicesProvider>
      <TagsProvider>
        <PortfolioProjectProvider>
          <ClientProvider>
            <ProjectProvider>
              <HoursProvider>
                <RetainerProvider>
                  <RoleProvider>
                    <DepartmentProvider>
                      <ValueChainProvider>
                        <AssetTypeProvider>
                          <AIAppProvider>
                            <ConversationProvider>
                              <FormSubmissionProvider>
                                <ThemeProvider theme={theme}>
                                  <CssBaseline />
                                  <Routes>
                                    <Route path="/login" element={<Login />} />
                                    <Route
                                      path="/*"
                                      element={
                                        isAuthenticated ? (
                                          <>
                                            <NavBar />
                                            <Suspense fallback={<CircularProgress />}>
                                              <Routes>
                                                <Route path="/" element={<Navigate to={getDefaultRoute()} replace />} />
                                                <Route path="/dashboard" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager']}>
                                                    <Dashboard />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/log-hours" element={
                                                  <ProtectedRoute allowedRoles={['member', 'manager', 'admin']}>
                                                    <LogHours />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/time/*" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager']}>
                                                    <Time />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/manage-departments-roles" element={
                                                  <ProtectedRoute allowedRoles={['admin']}>
                                                    <ManageDepartmentsRoles />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/value-chains" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager', 'member']}>
                                                    <ValueChainList />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/value-chain/:id" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager', 'member']}>
                                                    <ValueChainView />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/value-chain/:id/edit" element={
                                                  <ProtectedRoute allowedRoles={['admin']}>
                                                    <ValueChainCreator />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/value-chain/new" element={
                                                  <ProtectedRoute allowedRoles={['admin']}>
                                                    <ValueChainCreator />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/portfolio/*" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager', 'member', 'client']}>
                                                    <Portfolio />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/admin/*" element={
                                                  <ProtectedRoute allowedRoles={['admin']}>
                                                    <AdminPage />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/ai-apps" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager']}>
                                                    <AIPage />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/chat-app/:aiAppId" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager', 'member']}>
                                                    <ChatApp />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/chat-app/:aiAppId/:conversationId" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager', 'member']}>
                                                    <ChatApp />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/form-app/:aiAppId" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager', 'member']}>
                                                    <FormAIApp />
                                                  </ProtectedRoute>
                                                } />
                                                <Route path="/form-app/:aiAppId/:formSubmissionId" element={
                                                  <ProtectedRoute allowedRoles={['admin', 'manager', 'member']}>
                                                    <FormAIApp />
                                                  </ProtectedRoute>
                                                } />
                                              </Routes>
                                            </Suspense>
                                          </>
                                        ) : (
                                          <Navigate to="/login" replace />
                                        )
                                      }
                                    />
                                  </Routes>
                                </ThemeProvider>
                              </FormSubmissionProvider>
                            </ConversationProvider>
                          </AIAppProvider>
                        </AssetTypeProvider>
                      </ValueChainProvider>
                    </DepartmentProvider>
                  </RoleProvider>
                </RetainerProvider>
              </HoursProvider>
            </ProjectProvider>
          </ClientProvider>
        </PortfolioProjectProvider>
      </TagsProvider>
    </ServicesProvider>
  );
}

function App() {
  return (
    <UserProvider>
      <ClientProvider>
        <AIAppProvider>
          <ConversationProvider>
            <FormSubmissionProvider>
              <Router>
                <AppContent />
              </Router>
            </FormSubmissionProvider>
          </ConversationProvider>
        </AIAppProvider>
      </ClientProvider>
    </UserProvider>
  );
}

export default App;
