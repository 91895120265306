import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Avatar, Menu, MenuItem } from '@mui/material';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

function NavBar() {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
    navigate('/');
  };

  const handleAdminNavigation = () => {
    handleMenuClose();
    navigate('/admin');
  };

  const navBarContent = (
    <AppBar position="static" sx={{ 
      backgroundColor: 'rgba(0, 0, 0, 0.8)', 
      backdropFilter: 'blur(10px)',
    }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" component="div" sx={{ 
          fontWeight: 'bold', 
          background: 'linear-gradient(45deg, #CCCCCC 30%, #FFFFFF 90%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}>
          Creative X Entertainment Portal
        </Typography>
        {user && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {user.permission === 'admin' && <NavButton to="/ai-apps" label="AI Apps" />}
            <NavButton to="/portfolio" label="Portfolio" />
            {user.permission !== 'client' && (
              <>
                <NavButton to="/value-chains" label="Value Chains" />
                {(user.permission === 'admin' || user.permission === 'manager') && <NavButton to="/time" label="Time" />}
                <NavButton to="/log-hours" label="Log Hours" />
              </>
            )}
            <Avatar 
              sx={{ bgcolor: '#4a90e2', cursor: 'pointer' }}
              onClick={handleMenuOpen}
            >
              {user.name ? user.name[0].toUpperCase() : 'U'}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {user.permission === 'admin' && (
                <MenuItem onClick={handleAdminNavigation}>Admin</MenuItem>
              )}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );

  return navBarContent;
}

const NavButton = ({ to, label }) => {
  const location = useLocation();
  const isCurrentPage = (path) => {
    if (path === '/portfolio') {
      return location.pathname === '/portfolio' || location.pathname.startsWith('/portfolio/');
    }
    if (path === '/manage') {
      return location.pathname.startsWith('/manage');
    }
    if (path === '/admin') {
      return location.pathname.startsWith('/admin');
    }
    return location.pathname === path;
  };

  return (
    <Button 
      color="inherit" 
      component={RouterLink} 
      to={to}
      sx={{
        borderRadius: '4 px',
        backgroundColor: isCurrentPage(to) ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
        transition: 'all 0.3s',
        padding: '6px 16px',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          transform: 'translateY(0px)',
        },
      }}
    >
      {label}
    </Button>
  );
};

export default NavBar;
