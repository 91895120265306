import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import api from '../services/api';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { useClients } from './ClientContext';

const AIAppContext = createContext();

export const useAIApp = () => {
  const context = useContext(AIAppContext);
  if (!context) {
    throw new Error('useAIApp must be used within an AIAppProvider');
  }
  return context;
};

export const AIAppProvider = ({ children }) => {
  const [aiAppsData, setAIAppsData] = useState({ aiApps: [], currentPage: 1, totalPages: 1, totalItems: 0 });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAssociatedClients } = useClients();

  const fetchAIApps = useCallback(async (params = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/ai-apps', { params });
      const appsWithClients = response.data.aiApps.map(app => ({
        ...app,
        associatedClients: getAssociatedClients(app.Users?.map(user => user.id) || [])
      }));
      setAIAppsData({
        ...response.data,
        aiApps: appsWithClients
      });
      return {
        ...response.data,
        aiApps: appsWithClients
      };
    } catch (error) {
      console.error('Error fetching AI apps:', error);
      setError(error.response?.data?.error || 'Failed to fetch AI apps');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [getAssociatedClients]);

  const fetchAIApp = useCallback(async (appId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/ai-apps/${appId}`);
      const appData = response.data;
      
      // Parse the config if it's a string
      if (typeof appData.config === 'string') {
        try {
          appData.config = JSON.parse(appData.config);
        } catch (error) {
          console.error('Error parsing app config:', error);
          appData.config = {};
        }
      }
      
      setLoading(false);
      return appData;
    } catch (error) {
      console.error('Error fetching AI app:', error);
      setError(error.response?.data?.error || 'Failed to fetch AI app');
      throw error;
    }
  }, []);

  const fetchUserAIApps = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/ai-apps/user/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user AI apps:', error);
      setError(error.response?.data?.error || 'Failed to fetch user AI apps');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const runAIApp = useCallback(async (appId, data) => {
    try {
      const app = aiAppsData.aiApps.find(app => app.id === appId);
      if (!app) {
        throw new Error('AI App not found');
      }

      let config = app.config;
      if (typeof config === 'string') {
        try {
          config = JSON.parse(config);
        } catch (error) {
          console.error('Error parsing app config:', error);
          config = {};
        }
      } else if (typeof config !== 'object') {
        console.error('Invalid config type:', typeof config);
        config = {};
      }

      let variables = {};
      if (app.type === 'form') {
        config.inputVariables.forEach(varName => {
          if (data[varName] !== undefined) {
            variables[varName] = data[varName];
          }
        });
      } else {
        variables = data;
      }

      const token = Cookies.get('token');
      const result = await api.post(`/ai-apps/${appId}/run`, 
        { 
          ...variables, 
          workflow: data.workflow || app.defaultWorkflow, // Use provided workflow or default
          callbackUrl: data.callbackUrl, 
          threadId: data.threadId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (app.type === 'chat') {
        return { 
          result: result.data.result, 
          threadId: result.data.threadId,
          conversationId: result.data.conversationId, // Include conversation ID
          userMessage: result.data.userMessage,
          aiMessage: result.data.aiMessage,
        };
      } else {
        return result.data;
      }
    } catch (error) {
      console.error('Error running AI app:', error);
      throw error;
    }
  }, [aiAppsData.aiApps]);

  const updateAIApp = useCallback(async (id, updatedData) => {
    try {
      console.log('Updating AI app with data:', updatedData);
      
      const apiData = {
        ...updatedData,
        config: typeof updatedData.config === 'object' ? JSON.stringify(updatedData.config) : updatedData.config,
        defaultWorkflow: updatedData.defaultWorkflow, // Send the workflow text as-is
      };

      const response = await api.put(`/ai-apps/${id}`, apiData);
      console.log('Update response:', response.data);
      
      const updatedApp = response.data;

      setAIAppsData(prevData => ({
        ...prevData,
        aiApps: prevData.aiApps.map(app => app.id === id ? updatedApp : app)
      }));
      
      return updatedApp;
    } catch (error) {
      console.error('Error updating AI app:', error);
      if (error.response && error.response.data) {
        console.error('Error details:', error.response.data);
      }
      throw error;
    }
  }, []);

  const deleteAIApp = useCallback(async (appId, permanent = false) => {
    setLoading(true);
    setError(null);
    try {
      const endpoint = permanent ? `/ai-apps/${appId}/permanent` : `/ai-apps/${appId}`;
      await api.delete(endpoint);
      setAIAppsData(prevData => ({
        ...prevData,
        aiApps: prevData.aiApps.filter(app => app.id !== appId)
      }));
      setLoading(false);
    } catch (error) {
      console.error('Error deleting AI app:', error);
      setError(error.response?.data?.error || 'Failed to delete AI app');
      throw error;
    }
  }, []);

  const createAIApp = useCallback(async (data) => {
    setLoading(true);
    setError(null);
    try {
      const apiData = {
        name: data.name,
        type: data.type,
        mindstudioAppId: data.mindstudioAppId,
        config: data.config,
        defaultWorkflow: data.defaultWorkflow, // Send the workflow text as-is
        isActive: data.isActive,
        userIds: data.userIds,
      };

      console.log('Creating AI app with data:', apiData);
      
      const response = await api.post('/ai-apps', apiData);
      console.log('Create response:', response.data);
      
      const newApp = response.data;
      setAIAppsData(prevData => ({
        ...prevData,
        aiApps: [...prevData.aiApps, newApp],
        totalItems: prevData.totalItems + 1
      }));
      setLoading(false);
      return newApp;
    } catch (error) {
      console.error('Error creating AI app:', error);
      console.error('Error response:', error.response?.data);
      setError(error.response?.data?.error || 'Failed to create AI app');
      throw error;
    }
  }, []);

  const assignUserToAIApp = useCallback(async (appId, userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post(`/ai-apps/${appId}/users/${userId}`);
      setAIAppsData(prevData => ({
        ...prevData,
        aiApps: prevData.aiApps.map(app => app.id === appId ? response.data : app)
      }));
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error('Error assigning user to AI app:', error);
      setError(error.response?.data?.error || 'Failed to assign user to AI app');
      throw error;
    }
  }, []);

  const removeUserFromAIApp = useCallback(async (appId, userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.delete(`/ai-apps/${appId}/users/${userId}`);
      setAIAppsData(prevData => ({
        ...prevData,
        aiApps: prevData.aiApps.map(app => app.id === appId ? response.data : app)
      }));
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error('Error removing user from AI app:', error);
      setError(error.response?.data?.error || 'Failed to remove user from AI app');
      throw error;
    }
  }, []);

  const toggleAIAppActive = useCallback(async (appId) => {
    setLoading(true);
    setError(null);
    try {
      const app = aiAppsData.aiApps.find(app => app.id === appId);
      if (!app) {
        throw new Error('AI App not found');
      }
      
      // Only send the isActive field in the update request
      const response = await api.put(`/ai-apps/${appId}`, { isActive: !app.isActive });
      
      const updatedApp = response.data;
      setAIAppsData(prevData => ({
        ...prevData,
        aiApps: prevData.aiApps.map(app => app.id === appId ? updatedApp : app)
      }));
      setLoading(false);
      return updatedApp;
    } catch (error) {
      console.error('Error toggling AI app active state:', error);
      setError(error.response?.data?.error || 'Failed to toggle AI app active state');
      throw error;
    }
  }, [aiAppsData.aiApps]);

  const fetchActiveAIApps = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/ai-apps/active');
      setAIAppsData(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching active AI apps:', error);
      setError(error.response?.data?.error || 'Failed to fetch active AI apps');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchAIAppsByType = useCallback(async (type) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/ai-apps/type/${type}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching AI apps of type ${type}:`, error);
      setError(error.response?.data?.error || `Failed to fetch AI apps of type ${type}`);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  // Make sure to call fetchAIApps when the context is first loaded
  useEffect(() => {
    fetchAIApps();
  }, [fetchAIApps]);

  // Add a new function to fetch AI apps for a specific client
  const fetchClientAIApps = useCallback(async (clientId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/ai-apps/client/${clientId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching client AI apps:', error);
      setError(error.response?.data?.error || 'Failed to fetch client AI apps');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const resetAIApps = useCallback(() => {
    setAIAppsData({ aiApps: [], currentPage: 1, totalPages: 1, totalItems: 0 });
  }, []);

  const checkUserAccess = useCallback(async (appId, userId) => {
    try {
      const response = await api.get(`/ai-apps/${appId}/users/${userId}`);
      return response.data.hasAccess;
    } catch (error) {
      console.error('Error checking user access:', error);
      return false;
    }
  }, []);

  const value = {
    aiApps: aiAppsData,
    setAIAppsData,
    fetchAIApps,
    fetchAIApp,
    fetchUserAIApps,
    runAIApp,
    updateAIApp,
    deleteAIApp,
    createAIApp,
    assignUserToAIApp,
    removeUserFromAIApp,
    loading,
    error,
    toggleAIAppActive,
    fetchActiveAIApps,
    fetchAIAppsByType,
    fetchClientAIApps,
    resetAIApps,
    checkUserAccess,
  };

  return (
    <AIAppContext.Provider value={value}>
      {children}
    </AIAppContext.Provider>
  );
};
