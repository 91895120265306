import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import api from '../services/api';
import { parseISO } from 'date-fns'; // Add this import

const ConversationContext = createContext();

export function ConversationProvider({ children }) {
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchConversations = useCallback(async (userId, aiAppId, type = 'chat') => {
    setLoading(true);
    setError(null);
    try {
      let response;
      if (userId && aiAppId) {
        response = await api.get(`/conversations/app/${aiAppId}/user/${userId}`, { params: { type } });
      } else if (userId) {
        response = await api.get(`/conversations/user/${userId}`, { params: { type } });
      } else {
        throw new Error('Invalid parameters for fetching conversations');
      }
      // Filter out archived conversations and sort by updatedAt timestamp
      const activeConversations = response.data.filter(conv => !conv.archived);
      const sortedConversations = activeConversations.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setConversations(sortedConversations);
    } catch (err) {
      console.error('Error fetching conversations:', err);
      setError('Failed to fetch conversations');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMessages = useCallback(async (conversationId) => {
    if (messages[conversationId]) return;

    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/conversations/${conversationId}/messages`);
      const messagesWithTimestamps = response.data.map(message => ({
        ...message,
        timestamp: message.timestamp ? parseISO(message.timestamp) : new Date()
      }));
      setMessages(prev => ({ ...prev, [conversationId]: messagesWithTimestamps }));
    } catch (err) {
      setError('Failed to fetch messages');
      console.error('Error fetching messages:', err);
    } finally {
      setLoading(false);
    }
  }, [messages]);

  const createMessage = useCallback(async (conversationId, content, role, aiAppId, workflow) => {
    try {
      const timestamp = new Date();
      const conversation = conversations.find(conv => conv.id === conversationId);
      if (!conversation) {
        throw new Error('Conversation not found');
      }
      const threadId = conversation.threadId;

      const response = await api.post(`/conversations/${conversationId}/messages`, { 
        content, 
        role, 
        timestamp: timestamp.toISOString(),
        threadId,
        aiAppId,
        workflow // Add this line to pass the workflow
      });
      
      if (response.data.error) {
        throw new Error(response.data.error);
      }

      const { userMessage, aiMessage } = response.data;

      const userMessageWithTimestamp = { ...userMessage, timestamp };
      const aiMessageWithTimestamp = aiMessage ? { ...aiMessage, timestamp: new Date() } : null;

      setMessages(prev => ({
        ...prev,
        [conversationId]: [
          ...(prev[conversationId] || []),
          userMessageWithTimestamp,
          ...(aiMessageWithTimestamp ? [aiMessageWithTimestamp] : [])
        ]
      }));

      setConversations(prev => {
        const updatedConversation = prev.find(conv => conv.id === conversationId);
        const otherConversations = prev.filter(conv => conv.id !== conversationId);
        return [
          { ...updatedConversation, updatedAt: new Date() },
          ...otherConversations
        ];
      });

      return { userMessage: userMessageWithTimestamp, aiMessage: aiMessageWithTimestamp };
    } catch (err) {
      console.error('Error creating message:', err);
      return { error: err.message };
    }
  }, [conversations]);

  const createConversation = useCallback(async (aiAppId, name) => {
    try {
      const response = await api.post('/conversations', { aiAppId, name });
      const newConversation = response.data;
      setConversations(prev => [newConversation, ...prev]);
      return newConversation;
    } catch (err) {
      console.error('Error creating conversation:', err);
      throw err;
    }
  }, []);

  const renameConversation = useCallback(async (conversationId, newName) => {
    try {
      console.log('Renaming conversation in context:', conversationId, newName);
      const response = await api.patch(`/conversations/${conversationId}/name`, { name: newName });
      console.log('Rename API response:', response.data);
      const updatedConversation = response.data.conversation || response.data;
      setConversations(prev => {
        const updated = prev.map(conv => 
          conv.id === conversationId 
            ? { ...conv, name: newName }
            : conv
        );
        console.log('Updated conversations:', updated);
        return updated;
      });
      return updatedConversation;
    } catch (err) {
      console.error('Error renaming conversation:', err);
      throw err;
    }
  }, []);

  const archiveConversation = useCallback(async (conversationId) => {
    try {
      const response = await api.patch(`/conversations/${conversationId}/archive`);
      if (response.data.message === 'Conversation archived successfully') {
        setConversations(prev => prev.filter(conv => conv.id !== conversationId));
      }
      return response.data;
    } catch (err) {
      console.error('Error archiving conversation:', err);
      throw err;
    }
  }, []);

  const resetConversations = useCallback(() => {
    setConversations([]);
    setMessages({});
  }, []);

  const value = useMemo(() => ({
    conversations,
    setConversations,
    messages,
    setMessages,
    fetchConversations,
    createConversation,
    createMessage,
    fetchMessages,
    loading,
    error,
    renameConversation,
    archiveConversation,
    resetConversations,
  }), [
    conversations,
    messages,
    fetchConversations,
    createConversation,
    createMessage,
    fetchMessages,
    loading,
    error,
    renameConversation,
    archiveConversation,
    resetConversations,
  ]);

  return (
    <ConversationContext.Provider value={value}>
      {children}
    </ConversationContext.Provider>
  );
}

export function useConversation() {
  const context = useContext(ConversationContext);
  if (context === undefined) {
    throw new Error('useConversation must be used within a ConversationProvider');
  }
  return context;
}
