import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import api from '../services/api';
import { useUser } from './UserContext';

const ValueChainContext = createContext();

export function ValueChainProvider({ children }) {
  const [valueChains, setValueChains] = useState([]);
  const [currentValueChain, setCurrentValueChain] = useState(null);
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useUser();

  const fetchValueChains = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get('/value-chains');

      // Only filter if user is available
      if (user) {
        const filteredValueChains = data.filter(chain => {
          if (user.permission === 'admin') {
            return true; // Admins can see all value chains
          }
          
          const userRoleId = user.assignedRole?.id;
          const userDepartmentId = user.assignedRole?.department?.id;

          return (
            (chain.roles && chain.roles.some(role => role.id === userRoleId)) ||
            (chain.departments && chain.departments.some(dept => dept.id === userDepartmentId))
          );
        });

        setValueChains(filteredValueChains);
      } else {
        setValueChains(data);
      }
    } catch (error) {
      console.error('Error fetching value chains:', error);
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  }, [user]); // Keep user in the dependency array

  const fetchValueChain = useCallback(async (id) => {
    if (typeof id !== 'string' && typeof id !== 'number') {
      throw new Error('Invalid ID provided for value chain fetch');
    }
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get(`/value-chains/${id}`);
      setCurrentValueChain(data);
      return data;
    } catch (error) {
      console.error('Error fetching value chain:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const addValueChain = useCallback(async (valueChainData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post('/value-chains', valueChainData);
      setValueChains((prevChains) => [...prevChains, data]);
      return data;
    } catch (error) {
      console.error('Error adding value chain:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const updateValueChain = useCallback(async (id, valueChainData) => {
    if (!id) {
      return await addValueChain(valueChainData);
    }
    if ((typeof id !== 'string' && typeof id !== 'number') || id === '') {
      throw new Error('Invalid ID provided for value chain update');
    }
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.put(`/value-chains/${id}`, valueChainData);
      setValueChains((prevChains) => 
        prevChains.map((vc) => (vc.id === id ? data : vc))
      );
      if (currentValueChain && currentValueChain.id === id) {
        setCurrentValueChain(data);
      }
      return data;
    } catch (error) {
      console.error('Error updating value chain:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [addValueChain, currentValueChain]);

  const deleteValueChain = useCallback(async (id) => {
    setLoading(true);
    setError(null);
    try {
      await api.delete(`/value-chains/${id}`);
      setValueChains((prevChains) => prevChains.filter((vc) => vc.id !== id));
      if (currentValueChain && currentValueChain.id === id) {
        setCurrentValueChain(null);
      }
    } catch (error) {
      console.error('Error deleting value chain:', error);
      setError(error.response?.data?.message || error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [currentValueChain]);

  const fetchRoles = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get('/roles');
      setRoles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDepartments = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.get('/departments');
      setDepartments(data);
    } catch (error) {
      console.error('Error fetching departments:', error);
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchValueChains();
    fetchRoles();
    fetchDepartments();
  }, [fetchValueChains, fetchRoles, fetchDepartments]);

  return (
    <ValueChainContext.Provider
      value={{
        valueChains,
        currentValueChain,
        roles,
        departments,
        loading,
        error,
        fetchValueChains,
        fetchValueChain,
        addValueChain,
        updateValueChain,
        deleteValueChain,
        fetchRoles,
        fetchDepartments,
      }}
    >
      {children}
    </ValueChainContext.Provider>
  );
}

export function useValueChain() {
  return useContext(ValueChainContext);
}