import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { 
  List, ListItem, ListItemText, ListItemButton, Typography, Box, IconButton,
  Menu, MenuItem, Dialog, DialogTitle, DialogContent, TextField,
  DialogActions, Button, Alert,
  styled, alpha
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useFormSubmission } from '../../contexts/FormSubmissionContext';
import DialogContentText from '@mui/material/DialogContentText';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const FormConversationList = React.memo(({ aiAppId, userId, onSelectFormSubmission, selectedFormSubmissionId, onArchive }) => {
  const { 
    formSubmissions,
    fetchFormSubmissionsByUser,
    createFormSubmission, 
    renameFormSubmission, 
    archiveFormSubmission,
    loading, 
    error
  } = useFormSubmission();
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  useEffect(() => {
    if (userId && aiAppId) {
      fetchFormSubmissionsByUser(userId, { aiAppId });
    }
  }, [userId, aiAppId, fetchFormSubmissionsByUser]);

  const filteredSubmissions = useMemo(() => 
    formSubmissions
      .filter(submission =>
        submission.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !submission.isArchived
      )
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)), // Sort by updatedAt in descending order
    [formSubmissions, searchTerm]
  );

  useEffect(() => {
    // If no submission is selected and there are non-archived submissions, select the first one
    if (!selectedFormSubmissionId && filteredSubmissions.length > 0) {
      onSelectFormSubmission(filteredSubmissions[0]);
    }
  }, [selectedFormSubmissionId, filteredSubmissions, onSelectFormSubmission]);

  const handleMenuOpen = useCallback((event, submission) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedSubmission(submission);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleNewSubmission = useCallback(async () => {
    try {
      const newSubmission = await createFormSubmission(aiAppId, {}, 'New Submission');
      onSelectFormSubmission(newSubmission);
    } catch (error) {
      console.error('Error creating new submission:', error);
    }
  }, [aiAppId, createFormSubmission, onSelectFormSubmission]);

  const handleRenameClick = useCallback(() => {
    setIsRenameDialogOpen(true);
    setNewName(selectedSubmission.name);
    handleMenuClose();
  }, [selectedSubmission]);

  const handleRenameConfirm = useCallback(async () => {
    if (!newName.trim() || !selectedSubmission) return;
    try {
      await renameFormSubmission(selectedSubmission.id, newName);
      setIsRenameDialogOpen(false);
      setNewName('');
    } catch (error) {
      console.error('Failed to rename submission:', error);
    }
  }, [selectedSubmission, newName, renameFormSubmission]);

  const handleArchiveClick = useCallback(() => {
    setIsArchiveDialogOpen(true);
    handleMenuClose();
  }, []);

  const handleArchiveConfirm = useCallback(async () => {
    if (selectedSubmission) {
      try {
        await onArchive(selectedSubmission.id);
        setIsArchiveDialogOpen(false);
      } catch (error) {
        console.error('Error archiving submission:', error);
      }
    }
  }, [selectedSubmission, onArchive]);

  const handleSubmissionClick = useCallback((submission) => {
    // Prevent setting loading state when switching between items
    if (submission.id !== selectedFormSubmissionId) {
      onSelectFormSubmission(submission);
    }
  }, [onSelectFormSubmission, selectedFormSubmissionId]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', bgcolor: 'background.paper' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold' }}>Form Submissions</Typography>
        <IconButton
          onClick={handleNewSubmission}
          size="small"
          sx={{ color: 'primary.main' }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search submissions..."
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Search>
      {error && (
        <Alert severity="error" sx={{ mx: 2, mb: 2 }}>
          Error loading submissions. Please try again later.
        </Alert>
      )}
      <List sx={{ flexGrow: 1, overflow: 'auto', px: 1 }}>
        {loading && formSubmissions.length === 0 ? (
          <Typography sx={{ p: 2 }}>Loading submissions...</Typography>
        ) : filteredSubmissions.length === 0 ? (
          <Typography sx={{ p: 2 }}>No submissions found.</Typography>
        ) : (
          filteredSubmissions.map((submission) => (
            <ListItem
              key={submission.id}
              disablePadding
              sx={{ mb: 1 }}
            >
              <ListItemButton
                onClick={() => handleSubmissionClick(submission)}
                selected={selectedFormSubmissionId === submission.id}
                sx={{
                  borderRadius: 2,
                  '&.Mui-selected': {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                  },
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                <ListItemText 
                  primary={submission.name}
                  primaryTypographyProps={{ 
                    noWrap: true,
                    fontWeight: selectedFormSubmissionId === submission.id ? 'bold' : 'normal'
                  }}
                />
                <IconButton
                  edge="end"
                  aria-label="more"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMenuOpen(event, submission);
                  }}
                  sx={{ 
                    color: selectedFormSubmissionId === submission.id ? 'inherit' : 'action.active',
                    '&:hover': { backgroundColor: 'action.hover' },
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleRenameClick}>Rename</MenuItem>
        <MenuItem onClick={handleArchiveClick}>Archive</MenuItem>
      </Menu>

      <Dialog open={isRenameDialogOpen} onClose={() => setIsRenameDialogOpen(false)}>
        <DialogTitle>Rename Submission</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Name"
            type="text"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleRenameConfirm();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRenameDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleRenameConfirm} disabled={!newName.trim()}>Rename</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isArchiveDialogOpen} onClose={() => setIsArchiveDialogOpen(false)}>
        <DialogTitle>Archive Submission</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive this submission? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsArchiveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleArchiveConfirm} color="error">Archive</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default FormConversationList;
